import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { empty, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Captcha } from '../model';
import { HelperService } from './helper.service';
import { StorageService } from './storage.service';

@Injectable()
export class HttpClientService {
    constructor(
        private httpClient: HttpClient,
        private storage: StorageService,
        private router: Router,
        private helper: HelperService,
    ) {}

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public postClient(url: string, data?: any, options?: any): Observable<any> {
        return this.httpClient.post(url, data, options);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public post(url: string, data?: any, options?: any): Observable<any> {
        // let token = this.storage.getToken();
        const httpOptions = {
            // headers: new HttpHeaders({ authorization: 'Bearer ' + token.access_token })
        };
        if (options) {
            Object.assign(httpOptions, options);
        }
        return this.httpClient.post(url, data, httpOptions);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public put(url: string, data?: any, options?: any): Observable<any> {
        // let token = this.storage.getToken();
        const httpOptions = {
            // headers: new HttpHeaders({ authorization: 'Bearer ' + token.access_token })
        };
        if (options) {
            Object.assign(httpOptions, options);
        }
        return this.httpClient.put(url, data, httpOptions);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public delete(url: string, options?: any): Observable<any> {
        // let token = this.storage.getToken();
        const httpOptions = {
            // headers: new HttpHeaders({ authorization: 'Bearer ' + token.access_token })
        };
        if (options) {
            Object.assign(httpOptions, options);
        }
        return this.httpClient.delete(url, httpOptions);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public get(url: string, options?: any): Observable<any> {
        const httpOptions = {
            // headers: new HttpHeaders({ authorization: 'Bearer ' + token.access_token })
        };
        if (options) {
            Object.assign(httpOptions, options);
        }
        return this.httpClient.get(url, httpOptions);
    }

    // public get(url: string): Observable<any> {
    //     return this.request(url, { method: RequestMethod.Get })
    //         .map((response: Response) => response)
    //         .catch(error => {
    //             console.log('napaka pri get');
    //             console.log(error);
    //             return observableThrowError(error);
    //         });
    // }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getAttachment(url: string): Observable<HttpEvent<any>> {
        // let data = this.storage.getToken();
        const options = {
            responseType: 'blob',
            observe: 'response',
            // headers: new HttpHeaders({ authorization: 'Bearer ' + data.access_token })
        };
        const req = new HttpRequest('GET', url, options);
        return this.httpClient.request(req);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getAttachmentPost(url: string, data?: any): Observable<HttpEvent<any>> {
        //'blob' as 'json':=> https://github.com/angular/angular/issues/18586#issuecomment-336600793

        // let data = this.storage.getToken();
        const params = new HttpParams();
        const options = {
            headers: new HttpHeaders(),
            reportProgress: false,
            params: params,
            responseType: 'blob' as 'json',
            withCredentials: false,
        };

        // let options = {
        //     responseType: 'blob'
        //     // headers: new HttpHeaders({ authorization: 'Bearer ' + data.access_token })
        // };
        const req = new HttpRequest('POST', url, data, options);
        return this.httpClient.request(req);
    }

    // public downloadPDF(url): any {
    //     let data = this.storage.getToken();
    //     let options = {
    //         headers: new HttpHeaders({ 'authorization': 'Bearer ' + data.access_token})
    //     };

    //     return this.httpClient.get(url, { responseType: 'blob'}, options));
    // }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public uploadFile(url: string, file: File, comment?: string, params?: HttpParams): Observable<HttpEvent<any>> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('comment', comment);

        const data = this.storage.getToken();

        const options = {
            params: params || new HttpParams(),
            reportProgress: true,
            responseType: 'text' as const,
            headers: new HttpHeaders({ authorization: 'Bearer ' + data.access_token }),
        };

        // options.headers.append('content-length', '13110529');

        const req = new HttpRequest('POST', url, formData, options);
        return this.httpClient.request(req);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public refreshToken(): Observable<any> {
        const token = this.storage.getToken();

        const options = {
            headers: new HttpHeaders({
                authorization: 'Basic ' + environment.AUTHPASS,
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
        };

        return this.httpClient
            .post(this.helper.getAuthUrl(), 'refresh_token=' + _.get(token, 'refresh_token', '') + '&grant_type=refresh_token', options)
            .pipe(
                map(response => {
                    this.storage.saveToken(response);
                    return response;
                }),
                catchError(e => {
                    console.log(e);
                    // this.storage.clearToken();
                    this.router.navigate(['/login']);
                    return empty();
                }),
            );
    }

    public getCaptcha(): Observable<Captcha> {
        return this.httpClient.get(this.helper.getRootUrl() + 'captcha') as Observable<Captcha>;
    }
}
