import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import * as _ from 'lodash';
import * as moment from 'moment';
import { concat, forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { DATE_CONST } from '../../../../../../_shared/constants/date-constants.const';
import { BsType } from '../../../../../../_shared/enums/business.enum';
import { ExaminationType } from '../../../../../../_shared/enums/examination/examination-type.enum';
import { ReservationExaminationStatus } from '../../../../../../_shared/enums/examination/reservation-examination-status.enum';
import { PaperHistoryElement, PortfolioPaper, Result, ResultSet } from '../../../../../../_shared/model';
import { Customer } from '../../../../../../_shared/model/customer.model';
import { ObservationsManager } from '../../../../../../_shared/model/laboratory/observations-manager';
import { Case } from '../../../../../../_shared/model/schedule/case.model';
import { User } from '../../../../../../_shared/model/user.model';
import { ContractorSettings } from '../../../../../../_shared/model/userGuiSettings/contractorSettings.model';
import { LabScientificNotationPipe } from '../../../../../../_shared/pipes/lab-scientific-notation.pipe';
import { HelperService, MfToastService } from '../../../../../../_shared/services';
import { ActiveModulesService } from '../../../../../../_shared/services/activeModules.service';
import { BaseState } from '../../../../../../_shared/state/base/base.state';
import { ExaminationState } from '../../../../../../_shared/state/examinations/examinations-state';
import { CommonService, ResultService } from '../../../_services';
import { ReservationService } from '../../../_services/reservation.service';
import { PfType } from '../../_common/enum/pf-type.enum';
import { PfPBaseComponent } from '../pf-p-base/pf-p-base.component';
import { PfPCommnComunicationService } from '../_common/pf-p-comn-communication.service';

@Component({
    selector: 'pf-p-mdps-survey',
    templateUrl: './pf-p-mdps-survey.component.html',
    styles: [],
})
export class PfPMdpsSurveyComponent extends PfPBaseComponent implements OnInit {
    @Input() isNew = false;
    @Input() paper: PortfolioPaper;
    @Input() prereservationId: string;
    @Input() customer: Customer;

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public selectedDiagnoses: any[] = [];
    public searching = false;
    public searchFailed = false;
    public hideSearchingWhenUnsubscribed = new Observable(() => () => (this.searching = false));
    public inputSetsObj: ResultSet[] = [];
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public diagnose$: Observable<any[]>;
    public searchInput$: Subject<string> = new Subject<string>();
    public loading = false;
    private onDestroy$ = new Subject<void>();
    private case: Case;

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public tmceSettings: any = {
        menubar: false,
        statusbar: true,
        resize: true,
        toolbar: 'bold | italic | ',
        plugins: 'paste',
    };
    public footNote: string;
    public selectedContractorId: number = this.store.selectSnapshot(BaseState.activeContractorId);
    public loggedUser: User = this.store.selectSnapshot(BaseState.LoggedUser);
    public contractorSettings: ContractorSettings = this.store.selectSnapshot(BaseState.GetContractorSettings);

    constructor(
        public activeModal: NgbActiveModal,
        private commonRest: CommonService,
        private rest: ResultService,
        private toast: MfToastService,
        public comm: PfPCommnComunicationService,
        public helper: HelperService,
        public reservationRest: ReservationService,
        public activeModules: ActiveModulesService,
        public store: Store,
    ) {
        super(helper);
    }

    ngOnInit() {
        if (this.isNew) {
            this.createReport();
        } else {
            this.loadData(this.paper?.visitId, this.paper?.paperId || this.paper?.id);
        }
        this.communications();
        this.initSearch();
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngOnDestroy(): void {
        this.comm.dirty$.next(false);
        this.onDestroy$.next();
    }

    private initSearch(): void {
        this.diagnose$ = concat(
            of([]), // default items
            this.searchInput$.pipe(
                debounceTime(200),
                distinctUntilChanged(),
                tap(() => (this.searching = true)),
                switchMap(term =>
                    this.commonRest.searchDiagnose(term).pipe(
                        map(data => {
                            return data.map(el => ({
                                ...el,
                                fullValue: `${el.name}  (${el.code})`,
                            }));
                        }),
                        catchError(() => of([])), // empty list on error
                        tap(() => (this.searching = false)),
                    ),
                ),
            ),
        );
    }

    private loadData(visitId: number, paperId: number): void {
        this.rest
            .getSurveyPaper(visitId, paperId)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(res => {
                if (_.get(res, 'surveys', []).length > 0) {
                    //Kompatibilnost za nazaj
                    //če združen opis ne obstaja --> dodaj združen opis element na začetek in ostalim povečaj sortId
                    if (_.get(res, 'surveys[0].title')) {
                        res.surveys.map(survey => {
                            survey.sortId = String(Number(survey.sortId) + 1);
                            return survey;
                        });
                    }
                    this.inputSetsObj = res.surveys;
                    //Kompatibilnost za nazaj...
                    if (res.diagnose && !res.diagnoses) {
                        this.selectedDiagnoses = [res.diagnose];
                    } else if (res.diagnoses) {
                        this.selectedDiagnoses = res.diagnoses;
                    }
                }
                if (_.get(res, 'footNote')) {
                    this.footNote = res.footNote;
                }
                //init details form
                if (res.subcontractor) {
                    this.detailsForm.controls.subcontractor.setValue(res.subcontractor);
                }
                this.paper.fromTime = res?.fromTime;
            });
    }

    private communications(): void {
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
        this.comm.onCopy.pipe(takeUntil(this.onDestroy$)).subscribe((data: PaperHistoryElement) => {});

        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.comm.onSave.pipe(takeUntil(this.onDestroy$)).subscribe((data: boolean) => {
            this.saveSurvey().subscribe();
        });

        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.comm.onPreview.pipe(takeUntil(this.onDestroy$)).subscribe((data: boolean) => {
            this.openPreview(this.paper, false);
        });

        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.comm.onPrint.pipe(takeUntil(this.onDestroy$)).subscribe((data: boolean) => {
            this.openPreview(this.paper, true);
        });

        this.comm.onSignDocument.pipe(takeUntil(this.onDestroy$)).subscribe(data => {
            this.comm.saveSignShareAndShow(
                this.paper,
                data,
                () => this.saveSurvey(),
                casee => (this.case = casee),
                false,
                false,
                this.bsType === BsType.PREVENTIVNI,
            );
        });
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-empty-function
        this.comm.onTemplateSaveSurvey.pipe(takeUntil(this.onDestroy$)).subscribe((data: any) => {});
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.comm.onTemplateSelect.pipe(takeUntil(this.onDestroy$)).subscribe((data: any) => {
            super.selectTemplate(data, 'survey', this.inputSetsObj);
            this.comm.dirty$.next(true);
        });
        this.comm.resetValues.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
            this.inputSetsObj = [];
            this.selectedDiagnoses = [];
            this.createReport();
        });
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private addSurvey(survey: Result): Observable<any> {
        if (this.pfType == PfType.CUSTOMER) {
            return this.rest.addCustomerSurvey(this.selectedContractorId, this.customer?.id, survey);
        }
        return this.rest.addSurvey(this.paper.visitId, survey);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private updateSurvey(survey: Result): Observable<any> {
        if (this.pfType == PfType.CUSTOMER) {
            return this.rest.updateCustomerSurveyPaper(this.selectedContractorId, this.customer?.id, this.paper.paperId || this.paper.id, survey);
        }

        return this.rest.updateSurveyPaper(this.paper.visitId, this.paper.paperId || this.paper.id, survey);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private saveSurvey(): Observable<any> {
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const detailsFormValue: any = this.detailsForm?.value;
        if (this.detailsForm?.invalid) {
            this.toast.warning('Vsi obvezni podatki niso vnešeni');
            return of(undefined);
        }
        if (this.isNew) {
            let result;
            // TODO Ignored with eslint-interactive on 2023-11-10
            // eslint-disable-next-line prefer-const
            result = new Result().deserialize({
                surveys: this.inputSetsObj,
                surveyType: this.paper?.paperType,
                logo: undefined,
                diagnoses: this.selectedDiagnoses.map(diag => {
                    if (!diag.name) {
                        diag.name = diag.fullValue;
                    }
                    return diag;
                }),
                footNote: this.footNote,
                from: this.helper.stringDateToApiDate(detailsFormValue?.timeSpan?.from),
                to: this.helper.stringDateToApiDate(detailsFormValue?.timeSpan?.to),
                subcontractorId: this.detailsForm?.value?.subcontractor?.id, //samo ce je na customerju
                fromTime: this.contractorSettings?.portfolio?.portofiloPapersShowTime
                    ? moment(this.paper.created).format(DATE_CONST.format.time.timeValue)
                    : undefined,
            });

            return this.addSurvey(result).pipe(
                takeUntil(this.onDestroy$),
                tap(
                    res => {
                        this.paper = new PortfolioPaper().deserialize({
                            id: res,
                            paperId: res,
                            visitId: this.paper.visitId,
                            paperType: this.paper?.paperType,
                        });
                        this.comm.onPaperChanged.emit(this.paper);

                        this.toast.success('Spremembe so shranjene.');
                        this.isNew = false;
                        this.comm.dirty$.next(false);
                    },
                    () => this.toast.errorAndSend('Napaka pri dodajanju novega sklopa.'),
                ),
            );
        } else {
            //update
            return this.updateSurvey(
                new Result().deserialize({
                    surveys: this.inputSetsObj,
                    surveyType: this.paper?.paperType,
                    logo: undefined,
                    diagnoses: this.selectedDiagnoses
                        ? this.selectedDiagnoses.map(diag => {
                              if (!diag.name) {
                                  diag.name = diag.fullValue;
                              }
                              return diag;
                          })
                        : this.selectedDiagnoses,
                    footNote: this.footNote,
                    from: this.helper.stringDateToApiDate(detailsFormValue?.timeSpan?.from),
                    to: this.helper.stringDateToApiDate(detailsFormValue?.timeSpan?.to),
                    subcontractorId: this.detailsForm?.value?.subcontractor?.id, //samo ce je na customerju
                    fromTime: this.contractorSettings?.portfolio?.portofiloPapersShowTime
                        ? moment(this.paper.created).format(DATE_CONST.format.time.timeValue)
                        : undefined,
                }),
            ).pipe(
                takeUntil(this.onDestroy$),
                tap(
                    () => {
                        this.toast.success('Spremembe so shranjene.');
                        this.comm.dirty$.next(false);
                    },
                    () => this.toast.errorAndSend('Napaka pri urejanju sklopa.'),
                ),
            );
        }
    }

    public openPreview(paper: PortfolioPaper, print: boolean = false): void {
        this.rest
            .getSurveyPaperPreview(this.paper.visitId, paper.id)
            .pipe(
                takeUntil(this.onDestroy$),
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                map((res: any) => {
                    if (res.type !== 0) {
                        return new Blob([res.body], {
                            type: 'application/pdf',
                        });
                    }
                }),
            )
            .subscribe(
                res => {
                    if (res) {
                        this.helper.openDocumentViewer(res, res.type, 'Izvid', print);
                    }
                },
                () => {
                    this.toast.errorAndSend('Napaka pri predogledu poročila.');
                },
            );
    }

    private createReport(): void {
        this.store
            .select(ExaminationState.examinationsForReservation$)
            .pipe(
                first(),
                switchMap(examinationsForReservation => {
                    const diagnosisObservables = examinationsForReservation
                        .filter(
                            examinationForReservation =>
                                examinationForReservation.examination.type === ExaminationType.ANAMNESIS &&
                                examinationForReservation.diagnosis?.length > 0,
                        )
                        .map(examinationForReservation =>
                            forkJoin(
                                examinationForReservation.diagnosis.map(diagnosis =>
                                    this.commonRest.getDiagnosis(diagnosis).pipe(
                                        catchError(() => {
                                            this.toast.errorAndSend('Napaka pri nalaganju diagnoze.');
                                            return of(null);
                                        }),
                                    ),
                                ),
                            ),
                        );

                    return forkJoin(diagnosisObservables).pipe(
                        map(diagnoses => {
                            if (diagnoses?.length) {
                                this.selectedDiagnoses = diagnoses.flat().filter(diagnosis => diagnosis != null);
                            }

                            return examinationsForReservation;
                        }),
                    );
                }),
            )
            .subscribe(examinationsForReservation => {
                examinationsForReservation.forEach(examinationForReservation => {
                    let content = '';

                    if (examinationForReservation.status !== ReservationExaminationStatus.OK) {
                        return;
                    }

                    if (examinationForReservation.examination.type !== ExaminationType.EXTERNAL_LABIS) {
                        // MF Examination
                        content = examinationForReservation.examination.fields
                            .map(field => {
                                return `<strong>${field.name}:</strong> ${field.value != null ? field.value : '/'}`;
                            })
                            .flat()
                            .join('; ');
                        content = content.concat(
                            `<p><strong>Komentar:</strong> ${examinationForReservation.comment != null ? examinationForReservation.comment : ''}</p>`,
                        );
                    } else {
                        // Laboratory
                        const observationsVm = new ObservationsManager().getResultViewModel(examinationForReservation.orderInfo);
                        content = observationsVm
                            .map(specimenGroup => {
                                const labScientificNotationPipe = new LabScientificNotationPipe();
                                let resultString = `<p><strong>${specimenGroup.observationGroup.name}:</strong>`;
                                resultString = resultString.concat(
                                    `<br />${specimenGroup.resultEntries
                                        .map(entrie => {
                                            const observationDetails = `${entrie.observation.name}: ${entrie.value} [${
                                                labScientificNotationPipe.transform(entrie.unit) || '/'
                                            }] ${entrie.interpretationCode || ''} (${entrie.refLowRangeValue || '/'} - ${
                                                entrie.refHighRangeValue || '/'
                                            } ${entrie.comment || ''})`;
                                            const interpretationText =
                                                entrie.interpretationCode?.length > 0 ? `<strong>${observationDetails}</strong>` : observationDetails;
                                            return interpretationText;
                                        })
                                        .join('; ')}`,
                                );
                                return `${resultString}</p>`;
                            })
                            .flat()
                            .join('');
                    }
                    this.inputSetsObj.push(
                        new ResultSet().deserialize({
                            title: examinationForReservation.examination.name,
                            surveyId: '-1',
                            createdBy: this.loggedUser.name,
                            sortId: this.inputSetsObj.length,
                            content: content,
                        }),
                    );
                });
            });

        this.inputSetsObj.push(
            new ResultSet().deserialize({
                title: 'Priporočila',
                surveyId: '-1',
                createdBy: this.loggedUser.name,
                sortId: this.inputSetsObj.length,
            }),
        );
    }
}
