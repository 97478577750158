import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { forkJoin, from, Subject, switchMap, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { AppModulesType } from '../../../../../../_shared/enums/appModulesType.enum';
import { PortfolioPaper, SendInvoiceNotificationInfo } from '../../../../../../_shared/model';
import { Case } from '../../../../../../_shared/model/schedule/case.model';
import { HelperService, MfToastService, StorageService } from '../../../../../../_shared/services';
import { ActiveModulesService } from '../../../../../../_shared/services/activeModules.service';
import { VisitService } from '../../../_services';
import { PfPCommnComunicationService } from '../../papers/_common/pf-p-comn-communication.service';
import { Customer } from './../../../../../../_shared/model/customer.model';
import { CustomerGuardian } from './../../../../../../_shared/model/customer/customer-guardian.model';
import { BaseState } from './../../../../../../_shared/state/base/base.state';
import { DocumentViewerService } from './../../../../../basic-components/document-viewer/document-viewer.service';
import { CustomerService } from './../../../_services/customer.service';
import { DocumentsService } from './../../../_services/documents.service';
import { ResultService } from './../../../_services/result.service';

@Component({
    selector: 'pf-comn-actions',
    templateUrl: './pf-comn-actions.component.html',
    styles: [],
})
export class PfComnActionsComponent implements OnInit {
    @Input()
    disabled = false;

    @Input()
    public paper: PortfolioPaper;

    @Input()
    public case: Case;

    @Input()
    public customer: Customer;

    @Input()
    public detailsForm: FormGroup;
    // @Input()
    // public visit: Visit;

    // @Input()
    // public reservation: Reservation;

    private onDestroy$ = new Subject<void>();
    // private customer: Customer;

    public selectedContractorId: number = this.store.selectSnapshot(BaseState.activeContractorId);

    constructor(
        public activeModal: NgbActiveModal,
        private modalService: NgbModal,
        private visitRest: VisitService,
        // private storage: StorageService,
        private storage: StorageService,
        private customerRest: CustomerService,
        private toast: MfToastService,
        private comm: PfPCommnComunicationService,
        private helper: HelperService,
        private cust: CustomerService,
        public store: Store,
        private dvs: DocumentViewerService,
        private documentRest: DocumentsService,
        private resultRest: ResultService,
        private am: ActiveModulesService,
    ) {}

    ngOnInit() {
        this.comm.onPaperChanged.pipe(takeUntil(this.onDestroy$)).subscribe((newPaper: PortfolioPaper) => {
            this.paper = newPaper;
        });
        // if (this.visit) {
        //     this.customer = this.visit.customer;
        // } else {
        //     this.customer = this.reservation?.customer;
        // }
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

    public resultsPreview(): void {
        this.comm.onPreview.emit(true);
    }

    public resultsPrint(): void {
        this.comm.onPrint.emit(true);
    }

    private openSendDialogue(c: Customer | CustomerGuardian): void {
        this.dvs
            .shareDocument({
                email: c?.email,
                phone: c?.phone,
            })

            .pipe(
                takeUntil(this.onDestroy$),
                filter(receiver => receiver !== undefined),
                mergeMap((receiver: SendInvoiceNotificationInfo) =>
                    this.documentRest.sharePaper(this.selectedContractorId, c.id, this.paper.id, receiver),
                ),
            )
            .subscribe(
                () => this.toast.success('Listina uspešno deljena'),
                () => this.toast.errorAndSend('Napaka pri pošiljanju listine.'),
            );
    }

    public saveResults(): void {
        if (this.detailsForm?.invalid) {
            this.toast.warning('Vsi obvezni podatki niso vnešeni');
        } else {
            this.disabled = false;
            this.comm.onSave.emit(true);
        }
    }

    public sendPaper(): void {
        const isHd = this.am.isAM(AppModulesType.HELLO_DOCTOR);
        if (isHd && !this.paper.signedPaper) {
            this.toast.error('Listine ni mogoče poslati. Manjka podpis.');
            return;
        }

        forkJoin({
            customer: this.cust.getCustomer(this.selectedContractorId, this.customer?.id),
            guardian: this.cust.getCustomerGuardians(this.selectedContractorId, this.customer?.id),
        })
            .pipe(
                map(({ customer, guardian }) => {
                    if (guardian?.length > 0) {
                        return { ...guardian[0], id: customer.id };
                    }
                    return customer;
                }),
            )
            .subscribe((res: Customer | CustomerGuardian) => {
                this.openSendDialogue(res);
            });
    }

    public signDocument(): void {
        if (this.case) {
            //ce je case potem je treba prever
            this.customerRest
                .getCustomerConsent(this.storage.getSelectedContractor().id, this.customer?.id)
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(res =>
                    this.comm.onSignDocument.emit({
                        case: this.case,
                        canShareData: res?.insuranceConsent?.canShareData,
                    }),
                );
        } else {
            this.comm.onSignDocument.emit({ case: this.case });
        }
    }

    public closePopup() {
        if (this.comm?.dirty$ && this.comm.dirty$.getValue() == true) {
            this.helper.displayAlert('Listina ni shranjena.', 'Zaprem okno?').then(result => {
                if (result.value) {
                    this.activeModal.close();
                }
            });
        } else {
            this.activeModal.close();
        }
    }
    public deletePaper(): void {
        from(this.helper.displayAlert('Brisanje.', 'Izbrišem listino?'))
            .pipe(
                filter(result => result.value),
                switchMap(() => this.cust.deletePortfolioPaperFromCustomer(this.selectedContractorId, this.paper.id || this.paper.paperId)),
                tap(() => {
                    this.activeModal.close(true);
                    this.comm.onDelete.emit(true);
                }),
                catchError(err => {
                    this.toast.error('Napaka pri brisanju.');
                    return throwError(() => err);
                }),
            )
            .subscribe();
    }
}
