import { Injectable } from '@angular/core';
import { ObjectState, StatefullValue } from '@meddev/fe-shared';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';
import { VendorType } from '../../enums/vendor/vendor-type.enum';
import { DeviceObservationResponseDto } from '../../model/device/device-observation-response-dto';
import { DeviceResponseDto } from '../../model/device/device-response-dto';
import { LabisExaminationRequestDto } from '../../model/laboratory/lab-examination-request-dto';
import { ContractorResponseDto } from '../../model/vendor/contractor-response-dto';
import { VendorClientService } from '../../services/clients/vendor-client.service';
import { BaseState } from '../base/base.state';
import {
    LoadContractorDevices,
    LoadContractorSchiller,
    LoadDeviceObservations,
    SetSelectedContractor,
    SetSelectedDevice,
    SetSelectedDeviceObservation,
    SubmitDeviceOrder,
} from './device-actions';
import { DeviceStateModel } from './device-state.model';

const DEVICE_STATE_TOKEN: StateToken<DeviceStateModel> = new StateToken('devicestate');

const DEFAULT_STATE: DeviceStateModel = {
    selectedContractorId: { state: ObjectState.UNINIT, value: null } as StatefullValue<number>,
    selectedDeviceId: { state: ObjectState.UNINIT, value: null } as StatefullValue<string>,
    selectedDeviceObservationId: { state: ObjectState.UNINIT, value: null } as StatefullValue<string>,
    schillerContractors: { state: ObjectState.UNINIT, value: null } as StatefullValue<ContractorResponseDto[]>,
    contractorDevices: { state: ObjectState.UNINIT, value: null } as StatefullValue<DeviceResponseDto[]>,
    selectedDeviceObservations: { state: ObjectState.UNINIT, value: null } as StatefullValue<DeviceObservationResponseDto[]>,
    submit: { state: ObjectState.VALID, value: false } as StatefullValue<boolean>,
};

@State<DeviceStateModel>({
    name: DEVICE_STATE_TOKEN,
    defaults: DEFAULT_STATE,
    children: [],
})
@Injectable()
export class DeviceState {
    constructor(
        private store: Store,
        private vendorClientService: VendorClientService,
    ) {}

    @Selector([DEVICE_STATE_TOKEN])
    public static selectedDeviceId$(state: DeviceStateModel): StatefullValue<string> {
        return state.selectedDeviceId;
    }

    @Selector([DEVICE_STATE_TOKEN])
    public static selectedDeviceObservationId$(state: DeviceStateModel): StatefullValue<string> {
        return state.selectedDeviceObservationId;
    }

    @Selector([DEVICE_STATE_TOKEN])
    public static schillerContractors$(state: DeviceStateModel): StatefullValue<ContractorResponseDto[]> {
        return state.schillerContractors;
    }

    @Selector([DEVICE_STATE_TOKEN])
    public static contractorDevices$(state: DeviceStateModel): StatefullValue<DeviceResponseDto[]> {
        return state.contractorDevices;
    }

    @Selector([DEVICE_STATE_TOKEN])
    public static contractorDeviceObservations$(state: DeviceStateModel): StatefullValue<DeviceObservationResponseDto[]> {
        return state.selectedDeviceObservations;
    }

    @Selector([DEVICE_STATE_TOKEN])
    public static submitOrder$(state: DeviceStateModel): StatefullValue<boolean> {
        return state.submit;
    }

    @Action(SetSelectedContractor)
    public setSelectedContractor$(ctx: StateContext<DeviceStateModel>, action: SetSelectedContractor): Observable<StatefullValue<number>> {
        return of(action).pipe(
            map(
                () => ctx.patchState({ selectedContractorId: { state: ObjectState.VALID, value: action.selectedContractorId } }).selectedContractorId,
            ),
        );
    }

    @Action(SetSelectedDevice)
    public setSelectedDevice$(ctx: StateContext<DeviceStateModel>, action: SetSelectedDevice): Observable<unknown> {
        return of(action).pipe(
            tap(() =>
                ctx.patchState({
                    selectedDeviceId: { state: ObjectState.UNINIT, value: null },
                }),
            ),
            map(
                () =>
                    ctx.patchState({
                        selectedDeviceId: { state: action.selectedDeviceId ? ObjectState.VALID : ObjectState.UNINIT, value: action.selectedDeviceId },
                    }).selectedDeviceId,
            ),
            tap(() => {
                ctx.dispatch(new LoadDeviceObservations(action.selectedDeviceId));
            }),
        );
    }

    @Action(SetSelectedDeviceObservation)
    public setSelectedDeviceObservation(ctx: StateContext<DeviceStateModel>, action: SetSelectedDeviceObservation): Observable<unknown> {
        return of(action).pipe(
            tap(() =>
                ctx.patchState({
                    selectedDeviceObservationId: { state: ObjectState.UNINIT, value: null },
                }),
            ),
            map(
                () =>
                    ctx.patchState({
                        selectedDeviceObservationId: {
                            state: action.selectedDeviceObservationId ? ObjectState.VALID : ObjectState.UNINIT,
                            value: action.selectedDeviceObservationId,
                        },
                    }).selectedDeviceObservationId,
            ),
        );
    }

    @Action(LoadContractorSchiller)
    public loadSchiller$(ctx: StateContext<DeviceStateModel>): Observable<StatefullValue<ContractorResponseDto[]>> {
        return this.store.select(BaseState.activeContractorId).pipe(
            tap(() => ctx.patchState({ schillerContractors: { state: ObjectState.LOADING, value: null } })),
            switchMap(contractorId => {
                if (contractorId) {
                    return this.vendorClientService.getContractor(contractorId, VendorType.SCHILLER);
                }
                throw new Error();
            }),
            map(response => ctx.patchState({ schillerContractors: { state: ObjectState.VALID, value: response } }).schillerContractors),
            catchError(() => of(ctx.patchState({ schillerContractors: { state: ObjectState.ERROR, value: null } }).schillerContractors)),
            tap(() => {
                ctx.dispatch(new LoadContractorDevices());
            }),
        );
    }

    @Action(LoadContractorDevices)
    public loadVendorDevices$(ctx: StateContext<DeviceStateModel>): Observable<StatefullValue<DeviceResponseDto[]>> {
        return this.store.select(BaseState.activeContractorId).pipe(
            tap(() => ctx.patchState({ contractorDevices: { state: ObjectState.LOADING, value: null } })),
            switchMap(contractorId => {
                const schillerContractorId = ctx.getState().schillerContractors?.value[0]?.id;
                if (contractorId && schillerContractorId) {
                    return this.vendorClientService.getContractorDevices(contractorId, schillerContractorId);
                }
                throw new Error();
            }),
            map(response => ctx.patchState({ contractorDevices: { state: ObjectState.VALID, value: response } }).contractorDevices),
            catchError(() => of(ctx.patchState({ contractorDevices: { state: ObjectState.ERROR, value: null } }).contractorDevices)),
        );
    }

    @Action(LoadDeviceObservations)
    public loadDeviceObservations$(
        ctx: StateContext<DeviceStateModel>,
        action: LoadDeviceObservations,
    ): Observable<StatefullValue<DeviceObservationResponseDto[]>> {
        return this.store.select(BaseState.activeContractorId).pipe(
            tap(() => ctx.patchState({ selectedDeviceObservations: { state: ObjectState.LOADING, value: null } })),
            switchMap(contractorId => {
                const schillerContractorId = ctx.getState().schillerContractors?.value[0]?.id;
                if (contractorId && schillerContractorId && action.deviceId) {
                    return this.vendorClientService.getDeviceObservations(contractorId, schillerContractorId, action.deviceId);
                }
                ctx.patchState({ selectedDeviceObservations: { state: ObjectState.UNINIT, value: null } });
                return EMPTY;
            }),
            map(response => ctx.patchState({ selectedDeviceObservations: { state: ObjectState.VALID, value: response } }).selectedDeviceObservations),
            catchError(() =>
                of(ctx.patchState({ selectedDeviceObservations: { state: ObjectState.ERROR, value: null } }).selectedDeviceObservations),
            ),
        );
    }

    @Action(SubmitDeviceOrder)
    public submitDeviceOrder$(ctx: StateContext<DeviceStateModel>, action: SubmitDeviceOrder): Observable<StatefullValue<boolean>> {
        return this.store.select(BaseState.activeContractorId).pipe(
            first(),
            tap(() => ctx.patchState({ submit: { state: ObjectState.LOADING, value: false } })),
            switchMap(contractorId =>
                this.vendorClientService.createLabisReservationExamination(contractorId, action.preReservationId, {
                    labisContractorId: action.contractorId,
                    deviceId: action.deviceId,
                    observationIds: action.observationIds,
                } as LabisExaminationRequestDto),
            ),
            map(() => {
                ctx.patchState({ submit: { state: ObjectState.VALID, value: false } });
                return ctx.getState().submit;
            }),
            catchError(err => {
                console.error(err);
                ctx.patchState({ submit: { state: ObjectState.ERROR, value: false } });
                return of(ctx.getState().submit);
            }),
        );
    }
}
