export const environment = {
    RELEASE_STAGE: 'production',
    production: true,
    test: false,
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    VERSION: require('../../package.json').version,
    FILE_UPLOAD_MAX_SIZE: 52428800,
    AUTHPASS: btoa('test:test'),
    ERROR_URL: '',
    ERROR_NOTIFY_ACTIVE: true,
    INTERCOM_KEY: 'ky5rkdlu',
    TAWK_KEY: '5f1037aa5b59f94722bad7b6',
    termalPrinter: {
        port: '8080',
    },
    service: {
        v1: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            apiPort: '',
            apiUrl: '/erp/api',
        },
        v2: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            apiPort: '',
            apiUrl: '/eav2/erp/api',
        },
    },
    auth: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/oauth/token',
    },
    report: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/erp/report1',
    },
    document: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/erp/docapi',
    },
    printserver: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/eav2/erp/papi',
    },
    task: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/eav2/erp/taskapi',
    },
    crpp: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/eav2/crpp/api',
    },
    accounting: {
        v1: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            port: '',
            url: '/erp/aapi',
        },
        v2: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            port: '',
            url: '/eav2/erp/aapi',
        },
    },
    visit: {
        v1: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            port: '',
            url: '/erp/visitapi',
        },
        v2: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            port: '',
            url: '/eav2/erp/visitapi',
        },
    },
    communication: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/eav2/erp/communicationapi',
    },
    statistics: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/eav2/erp/statsapi',
    },
    surveys: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/eav2/surveys',
    },
    access: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/eav2/access',
    },
    assistance: {
        v1: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            port: '',
            url: '/assistance/api',
        },
        v2: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            port: '',
            url: '/v2/assistance/api',
        },
        v3: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            port: '',
            url: '/asv2/assistance',
        },
    },
    triage: {
        v1: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            port: '',
            url: '/erp/triageapi',
        },
        v3: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            port: '',
            url: '/eav2/erp/triageapi',
        },
    },
    telemed: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/erp/telemed',
    },
    onesignal: {
        appId: 'fdc55fb4-6851-4d64-aae3-89f45ea090d3',
        allowLocalhostAsSecureOrigin: false,
        defaultUrl: 'https://app.eambulanta.si',
        defaultTitle: 'eAmbulanta',
        safariWebId: 'web.onesignal.auto.2c53d929-118c-4db5-ba77-650d97dbe49e',
    },
    treatment: {
        v1: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            port: '',
            url: '/erp/treatments',
        },
        v3: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            port: '',
            url: '/eav2/erp/treatments',
        },
    },

    root: {
        v1: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            port: '',
            url: '',
        },
        v3: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            port: '',
            url: '',
        },
    },
    // root: {
    //     protocol: 'https://',
    //     rootUrl: 'app.eambulanta.si/booking',
    //     port: '',
    //     url: '',
    // },
    agora: {
        appId: '74694d6ab72646fea80f43f17aab66b6',
        logLevel: 2,
    },
    itero: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/erp/itero',
    },
    webSocket: {
        protocol: 'wss://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/ws/ea',
    },
    webSocketTelemed: {
        protocol: 'wss://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/ws/telemed/',
    },
    toolsUrl: {
        protocol: 'https://',
        rootUrl: 'link.eambulanta.si/tools',
        port: '',
        url: '',
    },

    widget: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/eav2/widgetapi',
    },
    wizard: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/eav2/wizardapi',
    },
    oim: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/eav2/oim/api',
    },
    widgetUrl: 'https://booking.eambulanta.si/widget/widget.min.js',
    webSocketHelloDoc: {
        reconnectInterval: 2000,
        protocol: 'wss://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/ws/hellodoctor',
    },
    aircall: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/eav2/erp/aircallapi',
    },
    erpRootUrl: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/eav2/erp',
    },
    eav2RootUrl: {
        protocol: 'https://',
        rootUrl: 'api.medifit.si/booking',
        port: '',
        url: '/eav2',
    },
    promApi: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/eav2/erp/promapi',
    },
    medical: {
        protocol: 'https://',
        rootUrl: 'api.medifit.si',
        port: '',
        url: '/medical',
    },
    unsupportedServices: [
        {
            id: 'd7574d81-6557-4538-80b1-5b0ed3c5d1b3',
            name: 'Paradontološki pregled s čiščenjem zobnega kamna',
            steps: [1, 2],
        },
        {
            id: '16c55377-b53c-42f7-a5b4-08305dc8e595',
            name: 'Neurofeedback - merjenje možganskega valovanja',
            steps: [1, 2],
        },
        {
            id: 'c964f2ca-bafe-4e0b-b6f0-909f59becbb5',
            name: 'Mamografija',
            steps: [1, 2],
        },
        {
            id: 'd88b40d4-6fa0-46dc-82be-108d50d5f101',
            name: 'Laboratorijski pregled krvi in urina z izvidom',
            steps: [1, 2, 4, 5, 6, 7],
        },
        {
            id: '33564a94-69dc-4856-a33f-da03e0b609df',
            name: 'PSA test',
            steps: [1, 2, 4, 5, 6, 7],
        },
        {
            id: '2376dc0a-9de9-4bb1-9b0f-b6c20a990246',
            name: 'Helikobakter - prisotnost želodčne bakterije',
            steps: [1, 2, 4, 5, 6, 7],
        },
        {
            id: 'a1e67843-68f9-48d3-b217-34c510d87ec5',
            name: 'Odvzem blata za ugotavljanje prikrite krvavitve v blatu',
            steps: [1, 2, 4, 5, 6, 7],
        },
        {
            id: '839211b6-fe81-40fd-8b93-82c011fa8cf0',
            name: 'Pregled za športno aktivne osebe',
            steps: [1, 2, 4, 5, 6, 7],
        },
        {
            id: 'b5216737-bf04-4a0a-a87b-4b1a2d64e451',
            name: 'Fotopletizmografska preiskava ven (oba uda)',
            steps: [1, 2],
        },
        {
            id: '59d9f313-72e9-4247-9f44-af75ac900ae8',
            name: 'Pregled stopal in meritve hoje',
            steps: [1, 2],
        },        
    ],

    G100FollowupServiceId: 6333,
};
