import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import _ from 'lodash';
import * as moment from 'moment';
import { NgxPermissionsService } from 'ngx-permissions';
import { EMPTY, from, of, Subject, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { DATE_CONST } from '../../../../_shared/constants/date-constants.const';
import { Customer } from '../../../../_shared/model';
import { ZipCode } from '../../../../_shared/model/zipCode.model';
import { MfToastService, StorageService } from '../../../../_shared/services';
import { CommonService, CustomerService } from '../../../pages/home/_services';
import { CustomerMultipleNewUserComponent } from '../customer-multiple-new-user/customer-multiple-new-user.component';
import { CustomerGuardian } from './../../../../_shared/model/customer/customer-guardian.model';
import { BaseState } from './../../../../_shared/state/base/base.state';

@Component({
    selector: 'cust-customer-details',
    templateUrl: './customer-details.component.html',
    styleUrls: ['./customer-details.component.scss'],
})
export class CustomerDetailsComponent implements OnInit, OnDestroy {
    @Input()
    customerForEdit: Customer;

    @Input()
    set setCustomer(customerForEdit: Customer) {
        this.customerForEdit = customerForEdit;
        this.init();
    }

    @Input()
    isNewCustomer = false;

    @Input()
    isReadOnly = false;

    @Output()
    saveCustomer: EventEmitter<boolean> = new EventEmitter();

    @Output()
    changeInput: EventEmitter<boolean> = new EventEmitter();

    private contractorId: number = this.store.selectSnapshot(BaseState.activeContractorId);
    private destroy$ = new Subject<void>();

    customerDetailsForm: FormGroup;
    customer: Customer;
    customerGuardian: CustomerGuardian;
    isPermissionsLoaded = false;
    isSubmited = false;
    dateOfBirthMask = [/[0-3]/, /\d/, '.', /[0-1]/, /\d/, '.', /[1-2]/, /\d/, /\d/, /\d/];
    zipCodes: ZipCode[] = [];

    get formData() {
        return this.customerDetailsForm?.controls;
    }

    constructor(
        private storage: StorageService,
        private commonRest: CommonService,
        private fb: FormBuilder,
        private rest: CustomerService,
        public toastr: MfToastService,
        private permissionsService: NgxPermissionsService,
        private datepipe: DatePipe,
        private mfToast: MfToastService,
        private modalService: NgbModal,
        private store: Store,
        private activeModal: NgbActiveModal,
    ) {}

    ngOnInit() {
        this.init();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private init() {
        this.commonRest
            .getZipCodes()
            .pipe(
                catchError(() => of([])),
                tap(zipCodes => {
                    if (!this.isReadOnly) {
                        this.zipCodes = zipCodes;
                    }
                }),
                switchMap(() => this.initCustomer$()),
            )
            .subscribe();
    }

    private initCustomer$() {
        if (!this.isNewCustomer) {
            if (this.customerForEdit) {
                this.customer = this.customerForEdit;
            } else {
                this.customer = this.storage.getSelectedCustomer();
            }

            if (!this.customer) {
                return of(undefined);
            }

            if (this.customer?.id) {
                return this.rest.getCustomerGuardians(this.contractorId, this.customer.id).pipe(
                    tap(guardian => {
                        this.customerGuardian = guardian.shift();
                        this.createForm();
                    }),
                );
            } else {
                this.createForm();
                return of(undefined);
            }
        } else {
            this.createForm();
            return of(undefined);
        }
    }

    createForm(): void {
        if (this.isNewCustomer) {
            this.customer = new Customer().deserialize({});
            this.customer.sendMail = true;
            this.customer.sendSms = true;
        }

        from(this.permissionsService.hasPermission('customer_details_canEdit'))
            .pipe(
                tap(isEditable => {
                    if (isEditable) {
                        isEditable = !this.isReadOnly;
                    }

                    this.isPermissionsLoaded = true;
                    this.customerDetailsForm = this.fb.group({
                        name: this.fb.control({ value: this.customer.name, disabled: !isEditable }, Validators.required),
                        surname: this.fb.control({ value: this.customer.surname, disabled: !isEditable }, Validators.required),
                        birthDate: this.fb.control(
                            {
                                value: this.datepipe.transform(this.customer.birthDate, DATE_CONST.format.date.ng),
                                disabled: !isEditable,
                            },
                            [Validators.pattern(/^\d{2}\.\d{2}\.\d{4}$|^$/)],
                        ),
                        address: this.fb.control({ value: this.customer.address, disabled: !isEditable }),
                        post: this.fb.control({ value: this.customer.post, disabled: !isEditable }),
                        city: this.fb.control({ value: this.customer.city, disabled: !isEditable }),
                        email: this.fb.control({ value: this.customer.email, disabled: !isEditable }),
                        phone: this.fb.control({ value: this.customer.phone, disabled: !isEditable }, [Validators.required, Validators.minLength(9)]),
                        sendMail: this.fb.control({ value: this.customer.sendMail, disabled: !isEditable }),
                        sendSms: this.fb.control({ value: this.customer.sendSms, disabled: !isEditable }),
                        customerId: this.fb.group({
                            kzz: this.fb.control({ value: this.customer.kzz, disabled: !isEditable }, [
                                Validators.maxLength(9),
                                Validators.minLength(9),
                                Validators.pattern('^[0-9]*$'),
                            ]),
                            emso: this.fb.control({ value: this.customer.emso, disabled: !isEditable }, [
                                Validators.maxLength(13),
                                Validators.minLength(13),
                                Validators.pattern('^[0-9]*$'),
                            ]),
                            taxNo: this.fb.control({ value: this.customer.taxNo, disabled: !isEditable }, [
                                Validators.maxLength(8),
                                Validators.minLength(8),
                                Validators.pattern('^[0-9]*$'),
                            ]),
                        }),
                        idCustomer: this.fb.control({ value: this.customer.id, disabled: true }),
                        gender: this.fb.control({ value: this.customer.gender, disabled: !isEditable }),
                        customerAdministrator: this.fb.group({
                            isActive: this.fb.control({ value: this.customerGuardian != undefined, disabled: !isEditable }),
                            name: this.fb.control({ value: this.customerGuardian?.name, disabled: !isEditable }, this.conditionallyRequiredValidator),
                            surname: this.fb.control(
                                { value: this.customerGuardian?.surname, disabled: !isEditable },
                                this.conditionallyRequiredValidator,
                            ),
                            email: this.fb.control({ value: this.customerGuardian?.email, disabled: !isEditable }),
                            phone: this.fb.control({ value: this.customerGuardian?.phone, disabled: !isEditable }, [
                                this.conditionallyRequiredValidator,
                                Validators.minLength(9),
                            ]),
                        }),
                    });
                }),
                switchMap(() => this.customerDetailsForm.valueChanges.pipe(takeUntil(this.destroy$))),
                tap(() => {
                    this.changeInput.next(true);
                }),
                switchMap(() => {
                    return this.customerDetailsForm.get('customerAdministrator').get('isActive').valueChanges.pipe(takeUntil(this.destroy$));
                }),
            )
            .subscribe(() => {
                const customerGuardianForm = this.customerDetailsForm.get('customerAdministrator');
                customerGuardianForm.get('name').updateValueAndValidity();
                customerGuardianForm.get('surname').updateValueAndValidity();
                customerGuardianForm.get('phone').updateValueAndValidity();
            });
    }

    onSubmitNewCustomer(forceSave: boolean) {
        this.isSubmited = true;
        if (!this.customerDetailsForm.valid) {
            return EMPTY;
        }
        const formModel = this.customerDetailsForm.value;
        formModel.name = this.trimName(formModel?.name);
        formModel.surname = this.trimName(formModel?.surname);
        formModel.emso = formModel.customerId.emso;
        formModel.kzz = formModel.customerId.kzz;
        formModel.taxNo = formModel.customerId.taxNo;

        if (formModel.birthDate) {
            formModel.birthDate = moment(formModel.birthDate, DATE_CONST.format.date.view).toDate();
        }
        const customer = new Customer().deserialize(formModel);
        const guardian = new CustomerGuardian().deserialize(formModel?.customerAdministrator);

        return this.rest.createCustomer(customer, forceSave).pipe(
            catchError(err => {
                this.mfToast.errorAndSend('Napaka pri dodajanju nove stranke.');
                return throwError(() => err);
            }),
            map(res => {
                if (res?.customers?.length > 0) {
                    this.multipleNewUser(customer, res.customers);
                    return undefined;
                } else {
                    this.toastr.success('Stranka je uspešno dodana.');
                    formModel.id = +res.aggregateId;
                    this.customerDetailsForm.markAsPristine();
                    return formModel;
                }
            }),
            filter(res => res != undefined),
            mergeMap(c => {
                if (!formModel?.customerAdministrator?.isActive) {
                    return of(c);
                }
                return this.rest.createCustomer(guardian, true).pipe(
                    mergeMap(g => {
                        guardian.id = g?.aggregateId;
                        return this.rest.appCustomerGuardian(this.contractorId, c?.id, guardian).pipe(map(() => c));
                    }),
                );
            }),
        );
    }

    onSubmitUpdateCustomerDetails() {
        this.isSubmited = true;
        if (!this.customerDetailsForm.valid) {
            return EMPTY;
        }
        //dodam 12 ur zardi ceasovnega pasu (je dodal 1 h in se je sprmenil dan...)
        if (this.customerDetailsForm.value.birthDate) {
            this.customerDetailsForm.value.birthDate = moment(this.customerDetailsForm.value.birthDate, DATE_CONST.format.date.view)
                .add(12, 'h')
                .toDate();
        }
        const updateSubContractor = this.customerDetailsForm.value;

        updateSubContractor.emso = updateSubContractor?.customerId?.emso?.trim();
        updateSubContractor.kzz = updateSubContractor?.customerId?.kzz?.trim();
        updateSubContractor.taxNo = updateSubContractor?.customerId?.taxNo?.trim();
        updateSubContractor.jobTitle = updateSubContractor?.jobTitle?.trim();
        updateSubContractor.jobCode = updateSubContractor?.jobCode?.trim();

        updateSubContractor.gender != undefined ? _.get(updateSubContractor, 'gender') : undefined;
        if (updateSubContractor.phone) {
            updateSubContractor.phone = updateSubContractor.phone.trim();
        }
        if (updateSubContractor.email) {
            updateSubContractor.email = updateSubContractor.email.trim();
        }

        return this.rest
            .updateCustomer(
                this.storage.getSelectedContractor().id,
                this.customer.id,
                new Customer().deserialize(this.trimValues(this.customerDetailsForm.value)),
            )
            .pipe(
                catchError(err => {
                    this.mfToast.errorAndSend('Napaka pri urejanju stranke.');
                    return throwError(() => err);
                }),
                map(() => {
                    this.storage.setSelectedCustomer(new Customer().deserialize(updateSubContractor));
                    this.toastr.success('Urejanje uspešno.');
                    const updatedCustomer = new Customer().deserialize(updateSubContractor);
                    updatedCustomer.id = this.customer.id;
                    this.customerDetailsForm.markAsPristine();
                    return updatedCustomer;
                }),
                mergeMap((res: Customer) => {
                    if (!this.customerGuardian && updateSubContractor?.customerAdministrator?.isActive) {
                        //ADD new customerguardian
                        const guardian = new CustomerGuardian().deserialize(updateSubContractor?.customerAdministrator);
                        return this.rest.createCustomer(guardian, true).pipe(
                            mergeMap(g => {
                                guardian.id = g?.aggregateId;
                                this.customerGuardian = guardian;
                                return this.rest.appCustomerGuardian(this.contractorId, res?.id, guardian);
                            }),
                        );
                    } else if (this.customerGuardian && updateSubContractor?.customerAdministrator?.isActive) {
                        //UPDATE customerguardian
                        const guardian = new CustomerGuardian().deserialize(updateSubContractor?.customerAdministrator);
                        return this.rest.updateCustomer(this.contractorId, this.customerGuardian.id, this.trimValues(guardian)).pipe(map(() => res));
                    } else if (this.customerGuardian && !updateSubContractor?.customerAdministrator?.isActive) {
                        //DELETE connection
                        return this.rest
                            .deleteCustomerGuardians(this.contractorId, this.customer.id, this.customerGuardian.id)
                            .pipe(mergeMap(() => this.rest.deactivateCustomer(this.contractorId, this.customerGuardian.id)));
                    }
                    return of(res);
                }),
            );
    }

    fillCity(e) {
        if (e.city) {
            this.customerDetailsForm.controls.city.setValue(e.city);
        } else {
            //če je mesto iz šifranta, pusti pri miru, če ni pa reset field
            const findCity = this.zipCodes.find(el => el.city == this.customerDetailsForm.value.city);
            if (findCity) {
                this.customerDetailsForm.controls.city.setValue(e.city);
            }
        }
    }
    fillPost(e) {
        if (e.zipCode) {
            this.customerDetailsForm.controls.post.setValue(e.zipCode);
        } else {
            //če je pošta iz šifranta, pusti pri miru, če ni pa reset field
            const findPost = this.zipCodes.find(el => el.zipCode == this.customerDetailsForm.value.post);
            if (findPost) {
                this.customerDetailsForm.controls.post.setValue(e.zipCode);
            }
        }
    }
    private multipleNewUser(customerNew: Customer, customers: Customer[]): void {
        this.toastr.info('V sistemu je vnešena stranka s podobnimi podatki.');
        const modalMultipleCustomers = this.modalService.open(CustomerMultipleNewUserComponent, {
            backdropClass: 'mf-second-modal-backdrop',
            size: 'lg',
            windowClass: 'mf-transparent-popup',
        });

        from(modalMultipleCustomers.result)
            .pipe(catchError(newCustomer => of(newCustomer)))
            .subscribe(newCustomer => {
                if (newCustomer) {
                    this.toastr.info('V sistemu je vnešena stranka s podobnimi podatki, uporabite iskalnik strank.');
                    this.activeModal.dismiss();
                } else {
                    this.saveCustomer.emit();
                }
            });
        modalMultipleCustomers.componentInstance.customerNew = customerNew;
        modalMultipleCustomers.componentInstance.customersOld = customers;
        modalMultipleCustomers.componentInstance.existingCustomer = true;
    }

    private trimName(name: string): string {
        return name
            ?.trim()
            ?.split(' ')
            ?.map(name => {
                return (name[0]?.toUpperCase() ? name[0]?.toUpperCase() : '') + name?.substring(1);
            })
            .join(' ');
    }

    private trimValues(data) {
        data.email != undefined ? (data.email = data.email.trim()) : undefined;
        data.emso != undefined ? (data.emso = data.emso.trim()) : undefined;
        data.phone != undefined ? (data.phone = data.phone.trim()) : undefined;
        data.name = this.trimName(data.name);
        data.surname = this.trimName(data.surname);
        for (const value in data.customerId) {
            if (data.customerId[value]) {
                data.customerId[value] = data.customerId[value].trim();
            }
        }
        return data;
    }

    private conditionallyRequiredValidator(formControl: AbstractControl) {
        if (!formControl.parent) {
            return null;
        }

        if (formControl?.parent?.get('isActive').value) {
            return Validators.required(formControl);
        }
        return null;
    }
}
