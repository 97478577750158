export enum ExaminationType {
    ANTHROPOMETRY = 'ANTHROPOMETRY',
    ANAMNESIS = 'ANAMNESIS',
    LAB_TEST = 'LAB_TEST',
    VISTION_TEST = 'VISTION_TEST',
    PERIMETRY = 'PERIMETRY',
    EKG = 'EKG',
    SPIROMETRY = 'SPIROMETRY',
    ADG = 'ADG',
    VTG = 'VTG',
    RTG = 'RTG',
    PSYCHOLOGIST = 'PSYCHOLOGIST',
    DRUG_TEST = 'DRUG_TEST',
    CDT = 'CDT',
    CUSTOM = 'CUSTOM',
    EXTERNAL_LABIS = 'EXTERNAL_LABIS',
    EXTERNAL_DEVICES = 'EXTERNAL_DEVICES',
}
