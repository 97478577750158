import * as moment from 'moment';
import { PaperType } from '../../enums/PaperType.enum';
import { Serializable } from '../deserialize.service';

export class PortfolioPaper implements Serializable<PortfolioPaper> {
    public id: number;
    public paperId: number;
    public visitId: number;
    public paperType: PaperType;
    public created: moment.Moment;
    public shared: boolean;
    public crppShared: boolean;
    public lastPublicAccess: moment.Moment | Date;
    public prereservationId: string;
    public from: string;
    public to: string;
    public paperDate: Date;
    public visitDate: Date;
    public signedPaper: boolean;
    public fromTime: string;
    public version: number;

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        this.id = input.id;
        this.paperId = input.paperId;
        this.paperType = input.paperType;
        this.from = input.from;
        this.to = input.to;
        this.visitId = input.visitId;
        this.paperDate = input.paperDate;
        this.visitDate = input.visitDate;
        this.prereservationId = input.prereservationId;
        this.signedPaper = input.signedPaper;
        this.fromTime = input.fromTime;
        this.version = input.version;
        if (input.created) {
            this.created = moment(input.created);
        }
        if (input.lastPublicAccess) {
            this.lastPublicAccess = moment(input.lastPublicAccess);
        }
        if (input.shared) {
            this.shared = input.shared;
        }
        if (input.crppShared) {
            this.crppShared = input.crppShared;
        }
        return this;
    }
}
