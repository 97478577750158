import { Customer } from '../../model';
import { PagedResponse } from '../../model/pagedResponse.model';
import { SearchSort } from '../../model/searchSort.model';

export class LoadCustomers {
    static readonly type = '[Customer] Load Customers';
    constructor(
        public contractorId: number,
        public page: number,
        public limit: number,
        public searchQuery: string,
        public sorts: SearchSort[] = [{ prop: 'surname', dir: 'asc' }],
    ) {}
}

export class SetCustomers {
    static readonly type = '[Customer] Set Customers';
    constructor(public customers: PagedResponse<Customer>) {}
}

export class DeleteCustomer {
    static readonly type = '[Customer] Delete Customer';
    constructor(
        public customerId: number,
        public contractorId: number,
    ) {}
}
