import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ExaminationFieldTemplateResponseDto } from '../../model/examinations/examination-field-template-response-dto';
import { ExaminationResponseDto, ReservationExaminationResponseDto } from '../../model/examinations/examination-response-dto';
import { ReservationExaminationFieldDto } from '../../model/examinations/reservation-examination-field-dto';
import { SaveReservationExaminationFieldsRequestDto } from '../../model/examinations/save-reservation-examination-fields-request-dto';
import { HelperService } from '../helper.service';
import { HttpClientService } from '../http-client.service';
import { MfToastService } from '../mfToast.service';

@Injectable({
    providedIn: 'root',
})
export class ExaminationsClientService {
    constructor(
        private httpClient: HttpClientService,
        private helper: HelperService,
        private toast: MfToastService,
    ) {}

    public getContractorExaminations(contractorId: number, getService = false, getFields = false): Observable<ExaminationResponseDto[]> {
        let params = new HttpParams();
        params = params.append('getService', getService);
        params = params.append('getFields', getFields);
        return this.httpClient.get(this.helper.getExaminationApiUrl(`/contractors/${contractorId}/examinations`), { params });
    }

    public getReservationExaminations(contractorId: number, prereservationId: string): Observable<ReservationExaminationResponseDto[]> {
        return this.httpClient.get(this.helper.getExaminationApiUrl(`/contractors/${contractorId}/reservations/${prereservationId}/examinations`));
    }

    public createReservationExamination(contractorId: number, prereservationId: string, examinationId: number): Observable<number> {
        return this.httpClient.post(
            this.helper.getExaminationApiUrl(`/contractors/${contractorId}/reservations/${prereservationId}/examinations/${examinationId}`),
        );
    }

    public deleteReservationExamination(contractorId: number, prereservationId: string, examinationId: number): Observable<number> {
        return this.httpClient.delete(
            this.helper.getExaminationApiUrl(`/contractors/${contractorId}/reservations/${prereservationId}/examinations/${examinationId}`),
        );
    }

    public saveReservationExaminationFields(
        contractorId: number,
        prereservationId: string,
        resExaminationId: number,
        requestBody: SaveReservationExaminationFieldsRequestDto,
    ): Observable<ReservationExaminationFieldDto[]> {
        return this.httpClient.post(
            this.helper.getExaminationApiUrl(
                `/contractors/${contractorId}/reservations/${prereservationId}/resexaminations/${resExaminationId}/fields`,
            ),
            requestBody,
        );
    }

    public deleteReservationExaminationField(
        contractorId: number,
        prereservationId: string,
        resExaminationId: number,
        fieldId: number,
    ): Observable<number> {
        return this.httpClient.delete(
            this.helper.getExaminationApiUrl(
                `/contractors/${contractorId}/reservations/${prereservationId}/resexaminations/${resExaminationId}/fields/${fieldId}`,
            ),
        );
    }

    public saveReservationExaminationComment(
        contractorId: number,
        prereservationId: string,
        resExaminationId: number,
        comment: string,
    ): Observable<number> {
        return this.httpClient.post(
            this.helper.getExaminationApiUrl(
                `/contractors/${contractorId}/reservations/${prereservationId}/resexaminations/${resExaminationId}/comment`,
            ),
            comment,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
            },
        );
    }

    public deleteReservationExaminationComment(contractorId: number, prereservationId: string, resExaminationId: number): Observable<number> {
        return this.httpClient.delete(
            this.helper.getExaminationApiUrl(
                `/contractors/${contractorId}/reservations/${prereservationId}/resexaminations/${resExaminationId}/comment`,
            ),
        );
    }

    public saveReservationExaminationDocument(
        contractorId: number,
        prereservationId: string,
        resExaminationId: number,
        maskedDocumentId: string,
    ): Observable<string> {
        const httpOptions = {
            responseType: 'text',
        };
        return this.httpClient.post(
            this.helper.getExaminationApiUrl(
                `/contractors/${contractorId}/reservations/${prereservationId}/resexaminations/${resExaminationId}/document/${maskedDocumentId}`,
            ),
            undefined,
            httpOptions,
        );
    }

    public deleteReservationExaminationDocument(
        contractorId: number,
        prereservationId: string,
        resExaminationId: number,
        maskedDocumentId: string,
    ): Observable<string> {
        const httpOptions = {
            responseType: 'text',
        };
        return this.httpClient.delete(
            this.helper.getExaminationApiUrl(
                `/contractors/${contractorId}/reservations/${prereservationId}/resexaminations/${resExaminationId}/document/${maskedDocumentId}`,
            ),
            httpOptions,
        );
    }

    public updateContractorExaminationService(contractorId: number, examinationId: number, serviceId: number): Observable<void> {
        return this.httpClient
            .put(this.helper.getExaminationApiUrl(`/contractors/${contractorId}/examinations/${examinationId}/service/${serviceId}`))
            .pipe(
                tap(() => this.toast.success('Storitev uspešno povezana s preiskavo')),
                catchError(() => {
                    this.toast.error('Prišlo je do napake.');
                    return EMPTY;
                }),
            );
    }

    public deleteContractorExaminationService(contractorId: number, examinationId: number): Observable<void> {
        return this.httpClient.delete(this.helper.getExaminationApiUrl(`/contractors/${contractorId}/examinations/${examinationId}/service`)).pipe(
            tap(() => this.toast.success('Storitev uspešno odstranjena s preiskave.')),
            catchError(() => {
                this.toast.error('Prišlo je do napake.');
                return EMPTY;
            }),
        );
    }

    getExaminationTemplates(contractorId: number, examinationId: number): Observable<ExaminationFieldTemplateResponseDto[]> {
        return this.httpClient.get(this.helper.getExaminationApiUrl(`/contractors/${contractorId}/examinations/${examinationId}/field-templates`));
    }

    createExaminationTemplate(
        contractorId: number,
        examinationId: number,
        templateName: string,
        fieldsWithValue: ReservationExaminationFieldDto[],
    ): Observable<number> {
        return this.httpClient.post(this.helper.getExaminationApiUrl(`/contractors/${contractorId}/examinations/${examinationId}/field-templates`), {
            name: templateName,
            fields: fieldsWithValue.map(field => ({ value: field.value, id: field.fieldId })),
        });
    }

    deleteExaminationTemplate(contractorId: number, examinationId: number, templateId: number): Observable<number> {
        return this.httpClient.delete(
            this.helper.getExaminationApiUrl(`/contractors/${contractorId}/examinations/${examinationId}/field-templates/${templateId}`),
        );
    }

    updateExaminationTemplate(
        contractorId: number,
        examinationId: number,
        templateId: number,
        templateName: string,
        fieldsWithValue: ReservationExaminationFieldDto[],
    ): Observable<number> {
        return this.httpClient.put(
            this.helper.getExaminationApiUrl(`/contractors/${contractorId}/examinations/${examinationId}/field-templates/${templateId}`),
            { name: templateName, fields: fieldsWithValue.map(field => ({ value: field.value, id: field.fieldId })) },
        );
    }

    updateExaminationDiagnosis(contractorId: number, prereservationId: string, examinationId: number, diagnosisCodes: string[]): Observable<number> {
        return this.httpClient.put(
            this.helper.getExaminationApiUrl(`/contractors/${contractorId}/reservations/${prereservationId}/examinations/${examinationId}/diagnosis`),
            { diagnosis: diagnosisCodes },
        );
    }
}
