import * as _ from 'lodash';
import * as moment from 'moment';
import { Serializable } from '../../deserialize.service';

export class IrInvoiceData implements Serializable<IrInvoiceData> {
    bottomLabel: string;
    bottomLabelMedical: string;
    contractorAddressName: string;
    contractorAddressNumber: string;
    contractorBankAccount: string;
    contractorBuildingNumber: number;
    contractorBuildingSectionNumber: number;
    contractorBusinessPremiseID: string;
    contractorCadastralNumber: number;
    contractorCity: string;
    contractorCommunity: string;
    contractorElectronicDeviceID: string;
    contractorEmail: string;
    contractorHouseNumber: string;
    contractorHouseNumberAdditional: string;
    contractorName: string;
    contractorNameHeaderData: string;
    contractorPhone: string;
    clinicPostalName: string;
    contractorPostalCode: string;
    contractorPostalName: string;
    contractorPostalNumber: string;
    contractorRegistrationNumber: string;
    contractorSpecialNotes: string;
    contractorStreet: string;
    contractorTaxId: string;
    contractorTaxNumber: number;
    contractorValidityDate: string;
    contractorWebPage: string;
    customerPayerAddressName: string;
    customerPayerCountryName: string;
    customerPayerID: string;
    customerPayerName: string;
    customerPayerPostalCode: string;
    customerPayerPostalName: string;
    customerPayerPostalBox: number;
    customerPayerSurname: string;
    customerServedAddressName: string;
    customerServedCountryName: string;
    customerServedID: string;
    customerServedName: string;
    customerServedPostalCode: string;
    customerServedPostalName: string;
    customerServedSurname: string;
    dataRecordBarCode: string;
    dateOfPayment: moment.Moment;
    dueDate: moment.Moment;
    eor: string;
    invoiceAmount: number;
    invoiceId: number;
    invoiceNumber: string;
    issueDateTime: moment.Moment;
    numberingStructure: string;
    operatorProtectedID: string;
    operatorTaxNumber: number;
    paymentAmount: number;
    paymentType: string;
    reference: string;
    referenceInvoiceId: number;
    softwareSupplierTaxNumber: number;
    title: string;
    userCashierID: string;
    userCashierName: string;
    userCashierSurname: string;
    wathermarkLabel: string;
    zoi: string;
    contractorPaymentDeadlineDays: number;
    contractorTaxPayer: boolean;
    visitStartDate: Date;
    footNote: string;
    servicesFromTo: string; //izpis datumo od - do na računu
    recipientName: string;
    recipientSurName: string;
    recipientAddressName: string;
    recipientPostalName: string;
    recipientPostalCode: string;
    recipientPostalBox: number;

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        if (_.isNil(input)) {
            return undefined;
        }
        Object.keys(input).forEach(key => (this[key] = input[key]));
        return this;
    }
}
