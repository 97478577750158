import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import * as _ from 'lodash';
import * as moment from 'moment';
import { concat, iif, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BsType } from '../../../../../../_shared/enums/business.enum';
import { PaperType } from '../../../../../../_shared/enums/PaperType.enum';
import { PaperHistoryElement, PortfolioPaper, Result, ResultSet } from '../../../../../../_shared/model';
import { HelperService, MfToastService } from '../../../../../../_shared/services';
import { CommonService, ResultService } from '../../../_services';
import { PfType } from '../../_common/enum/pf-type.enum';
import { PfPBaseComponent } from '../pf-p-base/pf-p-base.component';
import { PfPCommnComunicationService } from '../_common/pf-p-comn-communication.service';
import { DATE_CONST } from './../../../../../../_shared/constants/date-constants.const';
import { AppModulesType } from './../../../../../../_shared/enums/appModulesType.enum';
import { LogoType } from './../../../../../../_shared/enums/logoType.enum';
import { Customer } from './../../../../../../_shared/model/customer.model';
import { Case } from './../../../../../../_shared/model/schedule/case.model';
import { User } from './../../../../../../_shared/model/user.model';
import { ContractorSettings } from './../../../../../../_shared/model/userGuiSettings/contractorSettings.model';
import { ActiveModulesService } from './../../../../../../_shared/services/activeModules.service';
import { GetAndLoadSubcontractors } from './../../../../../../_shared/state/base/base.actions';
import { BaseState } from './../../../../../../_shared/state/base/base.state';
import { ReservationService } from './../../../_services/reservation.service';

@Component({
    selector: 'visit-pf-p-survey',
    templateUrl: './pf-p-survey.component.html',
    styles: [],
})
export class PfPSurveyComponent extends PfPBaseComponent implements OnInit {
    @Input()
    isNew = false;

    @Input()
    paper: PortfolioPaper;

    @Input()
    prereservationId: string;

    @Input()
    customer: Customer;

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public selectedDiagnoses: any[] = [];
    public searching = false;
    public searchFailed = false;
    public hideSearchingWhenUnsubscribed = new Observable(() => () => (this.searching = false));
    public inputSetsObj: ResultSet[] = [];
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public diagnose$: Observable<any[]>;
    public searchInput$: Subject<string> = new Subject<string>();
    public loading = false;
    public isTelemedActive = false;
    private onDestroy$ = new Subject<void>();
    private case: Case;
    public isHDActive = false;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public tmceSettings: any = {
        menubar: false,
        statusbar: true,
        resize: true,
        toolbar: 'bold | italic | ',
        plugins: 'paste',
    };
    public footNote: string;
    public selectedContractorId: number = this.store.selectSnapshot(BaseState.activeContractorId);
    public loggedUser: User = this.store.selectSnapshot(BaseState.LoggedUser);
    public contractorSettings: ContractorSettings = this.store.selectSnapshot(BaseState.GetContractorSettings);

    private isCustomer = false;

    // public allDiagnose: any[] = ['test', 'neki', 'krava'];
    constructor(
        public activeModal: NgbActiveModal,
        private commonRest: CommonService,
        private rest: ResultService,
        // private storage: StorageService,
        private toast: MfToastService,
        public comm: PfPCommnComunicationService,
        public helper: HelperService,
        public reservationRest: ReservationService,
        public activeModules: ActiveModulesService,
        public store: Store,
    ) {
        super(helper);
    }

    ngOnInit() {
        this.isHDActive = this.activeModules.isAM(AppModulesType.HELLO_DOCTOR);
        this.isCustomer = this.paper.paperType == PaperType.SURVEY_CUSTOMER;

        if (this.isNew) {
            this.createReport();
        } else {
            this.loadData(this.paper?.visitId, this.paper?.paperId || this.paper?.id);
        }
        this.checkForTelemed();
        this.communications();
        this.initSearch();
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngOnDestroy(): void {
        this.comm.dirty$.next(false);
        this.onDestroy$.next();
    }

    private initSearch(): void {
        this.diagnose$ = concat(
            of([]), // default items
            this.searchInput$.pipe(
                debounceTime(200),
                distinctUntilChanged(),
                tap(() => (this.searching = true)),
                switchMap(term =>
                    this.commonRest.searchDiagnose(term).pipe(
                        map(data => {
                            return data.map(el => ({
                                ...el,
                                fullValue: `${el.name}  (${el.code})`,
                            }));
                        }),
                        catchError(() => of([])), // empty list on error
                        tap(() => (this.searching = false)),
                    ),
                ),
            ),
        );
    }
    public checkForTelemed() {
        if (this.isHDActive && this.prereservationId) {
            this.reservationRest
                .getReservation(this.selectedContractorId, this.prereservationId)
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(data => {
                    this.isTelemedActive = data.telemed;
                });
        }
    }
    private loadData(visitId: number, paperId: number): void {
        let apiCall = this.rest.getSurveyPaper(visitId, paperId);
        if (this.isCustomer) {
            apiCall = this.rest.getCustomerSurveyPaper(this.selectedContractorId, this.customer?.id, paperId).pipe(
                mergeMap(result =>
                    this.store.dispatch(new GetAndLoadSubcontractors()).pipe(
                        mergeMap(() => this.store.select(BaseState.getAllSubcontractors())),
                        takeUntil(this.onDestroy$),
                        map(subc => {
                            result.subcontractor = subc.find(el => el.id == result.subcontractorId);
                            return result;
                        }),
                        // tap((subcontractors: Subcontractor[]) => (this.allSubcontractors = subcontractors)),
                    ),
                ),
            );
        }
        apiCall.pipe(takeUntil(this.onDestroy$)).subscribe(res => {
            if (_.get(res, 'surveys', []).length > 0) {
                //Kompatibilnost za nazaj
                //če združen opis ne obstaja --> dodaj združen opis element na začetek in ostalim povečaj sortId
                if (_.get(res, 'surveys[0].title') !== 'Združen opis') {
                    res.surveys.map(survey => {
                        survey.sortId = String(Number(survey.sortId) + 1);
                        return survey;
                    });
                    res.surveys.unshift(
                        new ResultSet().deserialize({
                            title: 'Združen opis',
                            sortId: '1',
                        }),
                    );
                }
                this.inputSetsObj = res.surveys;
                //Kompatibilnost za nazaj...
                if (res.diagnose && !res.diagnoses) {
                    this.selectedDiagnoses = [res.diagnose];
                } else if (res.diagnoses) {
                    this.selectedDiagnoses = res.diagnoses;
                }
            }
            if (_.get(res, 'footNote')) {
                this.footNote = res.footNote;
            }
            //init details form
            if (res.subcontractor) {
                this.detailsForm.controls.subcontractor.setValue(res.subcontractor);
            }
            this.paper.fromTime = res?.fromTime;
        });
    }

    private communications(): void {
        this.comm.onCopy.pipe(takeUntil(this.onDestroy$)).subscribe((data: PaperHistoryElement) => {
            this.copyFromHistory(data);
        });

        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.comm.onSave.pipe(takeUntil(this.onDestroy$)).subscribe((data: boolean) => {
            this.saveSurvey().subscribe();
        });

        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.comm.onPreview.pipe(takeUntil(this.onDestroy$)).subscribe((data: boolean) => {
            this.openPreview(this.paper, false);
        });

        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.comm.onPrint.pipe(takeUntil(this.onDestroy$)).subscribe((data: boolean) => {
            this.openPreview(this.paper, true);
        });

        this.comm.onSignDocument.pipe(takeUntil(this.onDestroy$)).subscribe(data => {
            this.comm.saveSignShareAndShow(
                this.paper,
                data,
                () => this.saveSurvey(),
                casee => (this.case = casee),
                false,
                false,
                this.bsType === BsType.PREVENTIVNI,
            );
        });
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.comm.onTemplateSaveSurvey.pipe(takeUntil(this.onDestroy$)).subscribe((data: any) => {
            if (!data.name) {
                this.saveTemplate(data).subscribe(result => {
                    this.comm.onTemplateSaveSurvey.next({ id: result, name: data });
                });
            }
        });
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.comm.onTemplateSelect.pipe(takeUntil(this.onDestroy$)).subscribe((data: any) => {
            super.selectTemplate(data, 'survey', this.inputSetsObj);
            this.comm.dirty$.next(true);
        });
        this.comm.resetValues.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
            this.inputSetsObj = [];
            this.selectedDiagnoses = [];
            this.createReport();
        });
    }

    private copyFromHistory(data: PaperHistoryElement): void {
        this.comm.dirty$.next(true);
        this.loadData(data.visitId, data.paperId);
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public saveTemplate(name: string): Observable<any> {
        return this.rest.saveDocumentTemplate(
            this.selectedContractorId,
            name,
            new Result().deserialize({
                surveys: this.inputSetsObj,
                surveyType: this.paper?.paperType,
                diagnoses: this.selectedDiagnoses,
            }),
        );
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private addSurvey(survey: Result): Observable<any> {
        if (this.pfType == PfType.CUSTOMER) {
            return this.rest.addCustomerSurvey(this.selectedContractorId, this.customer?.id, survey);
        }
        return this.rest.addSurvey(this.paper.visitId, survey);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private updateSurvey(survey: Result): Observable<any> {
        if (this.pfType == PfType.CUSTOMER) {
            return this.rest.updateCustomerSurveyPaper(this.selectedContractorId, this.customer?.id, this.paper.paperId || this.paper.id, survey);
        }

        return this.rest.updateSurveyPaper(this.paper.visitId, this.paper.paperId || this.paper.id, survey);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private saveSurvey(): Observable<any> {
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const detailsFormValue: any = this.detailsForm?.value;
        if (this.detailsForm?.invalid) {
            this.toast.warning('Vsi obvezni podatki niso vnešeni');
            return of(undefined);
        }
        if (this.isNew) {
            let result;
            // TODO Ignored with eslint-interactive on 2023-11-10
            // eslint-disable-next-line prefer-const
            result = new Result().deserialize({
                surveys: this.inputSetsObj,
                surveyType: this.paper?.paperType,
                logo: !this.isTelemedActive && this.isHDActive ? LogoType.MEDIFIT : undefined,
                diagnoses: this.selectedDiagnoses.map(diag => {
                    if (!diag.name) {
                        diag.name = diag.fullValue;
                    }
                    return diag;
                }),
                footNote: this.footNote,
                from: this.helper.stringDateToApiDate(detailsFormValue?.timeSpan?.from),
                to: this.helper.stringDateToApiDate(detailsFormValue?.timeSpan?.to),
                subcontractorId: this.detailsForm?.value?.subcontractor?.id, //samo ce je na customerju
                fromTime: this.contractorSettings?.portfolio?.portofiloPapersShowTime
                    ? moment(this.paper.created).format(DATE_CONST.format.time.timeValue)
                    : undefined,
            });

            return this.addSurvey(result).pipe(
                takeUntil(this.onDestroy$),
                tap(
                    res => {
                        this.paper = new PortfolioPaper().deserialize({
                            id: res,
                            paperId: res,
                            visitId: this.paper.visitId,
                            paperType: this.paper?.paperType,
                        });
                        this.comm.onPaperChanged.emit(this.paper);

                        this.toast.success('Spremembe so shranjene.');
                        this.isNew = false;
                        this.comm.dirty$.next(false);
                    },
                    () => this.toast.errorAndSend('Napaka pri dodajanju novega sklopa.'),
                ),
            );
        } else {
            //update
            return this.updateSurvey(
                new Result().deserialize({
                    surveys: this.inputSetsObj,
                    surveyType: this.paper?.paperType,
                    logo: !this.isTelemedActive && this.isHDActive ? LogoType.MEDIFIT : undefined,
                    diagnoses: this.selectedDiagnoses
                        ? this.selectedDiagnoses.map(diag => {
                              if (!diag.name) {
                                  diag.name = diag.fullValue;
                              }
                              return diag;
                          })
                        : this.selectedDiagnoses,
                    footNote: this.footNote,
                    from: this.helper.stringDateToApiDate(detailsFormValue?.timeSpan?.from),
                    to: this.helper.stringDateToApiDate(detailsFormValue?.timeSpan?.to),
                    subcontractorId: this.detailsForm?.value?.subcontractor?.id, //samo ce je na customerju
                    fromTime: this.contractorSettings?.portfolio?.portofiloPapersShowTime
                        ? moment(this.paper.created).format(DATE_CONST.format.time.timeValue)
                        : undefined,
                }),
            ).pipe(
                takeUntil(this.onDestroy$),
                tap(
                    () => {
                        this.toast.success('Spremembe so shranjene.');
                        this.comm.dirty$.next(false);
                    },
                    () => this.toast.errorAndSend('Napaka pri urejanju sklopa.'),
                ),
            );
        }
    }

    public openSignedPreview(paper: PortfolioPaper): void {
        this.comm
            .openSignedPreviewAppend(paper, this.case, 'Izvid')
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
                () => {
                    this.toast.success('Dokument dodan k avtorizaciji');
                },
                data => {
                    if (data !== 'close-no-error') {
                        this.toast.error('Napaka pri dodajanju dokumenta k avtorizaciji');
                    }
                },
            );
    }

    public openPreview(paper: PortfolioPaper, print: boolean = false): void {
        iif(
            () => this.isCustomer,
            this.rest.getPortfolioPaper(this.selectedContractorId, paper.paperId),
            this.rest.getSurveyPaperPreview(this.paper.visitId, paper.id),
        )
            .pipe(
                takeUntil(this.onDestroy$),
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                map((res: any) => {
                    if (res.type !== 0) {
                        return new Blob([res.body], {
                            type: 'application/pdf',
                        });
                    }
                    // Helpers.setLoading(false);
                }),
            )
            .subscribe(
                res => {
                    if (res) {
                        this.helper.openDocumentViewer(res, res.type, 'Izvid', print);
                    }
                },
                () => {
                    this.toast.errorAndSend('Napaka pri predogledu poročila.');
                },
            );
    }

    private createReport(): void {
        this.inputSetsObj.push(
            new ResultSet().deserialize({
                title: 'Združen opis',
                surveyId: '-1',
                createdBy: this.loggedUser.name,
                sortId: 1,
            }),
        );
        this.inputSetsObj.push(
            new ResultSet().deserialize({
                title: 'Anamneza',
                surveyId: '-1',
                createdBy: this.loggedUser.name,
                sortId: 2,
            }),
        );
        this.inputSetsObj.push(
            new ResultSet().deserialize({
                title: 'Status',
                surveyId: '-1',
                createdBy: this.loggedUser.name,
                sortId: 3,
            }),
        );
        this.inputSetsObj.push(
            new ResultSet().deserialize({
                title: 'Mnenje',
                surveyId: '-1',
                createdBy: this.loggedUser.name,
                sortId: 4,
            }),
        );
    }
}
