import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import _ from 'lodash';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AppModulesType } from '../../../../../../_shared/enums/appModulesType.enum';
import { BsType } from '../../../../../../_shared/enums/business.enum';
import { PaperType } from '../../../../../../_shared/enums/PaperType.enum';
import { Event } from '../../../../../../_shared/model';
import { PaperHistoryElement } from '../../../../../../_shared/model/portfolio/paperHistoryElement.model';
import { PortfolioPaper } from '../../../../../../_shared/model/portfolio/portfolioPaper.model';
import { Result } from '../../../../../../_shared/model/result.model';
import { ResultSet } from '../../../../../../_shared/model/resultSet.model';
import { Case } from '../../../../../../_shared/model/schedule/case.model';
import { ActiveModulesService } from '../../../../../../_shared/services/activeModules.service';
import { HelperService } from '../../../../../../_shared/services/helper.service';
import { MfToastService } from '../../../../../../_shared/services/mfToast.service';
import { ResultService } from '../../../_services/result.service';
import { PfPBaseComponent } from '../pf-p-base/pf-p-base.component';
import { PfPCommnComunicationService } from '../_common/pf-p-comn-communication.service';
import { LogoType } from './../../../../../../_shared/enums/logoType.enum';
import { User } from './../../../../../../_shared/model/user.model';
import { BaseState } from './../../../../../../_shared/state/base/base.state';
import { CommonService } from './../../../_services/common.service';
import { ReservationService } from './../../../_services/reservation.service';

@Component({
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'pf-p-mfm-hdSurvey',
    templateUrl: './pf-p-mfm-hdSurvey.component.html',
    styles: [],
})
export class PfPMFMSoapSurveyComponent extends PfPBaseComponent implements OnInit {
    @Input()
    isNew = false;

    @Input()
    paper: PortfolioPaper;

    @Input()
    prereservationId: string;

    @Input()
    termData: Event;

    // @Input()
    // id: string;

    public searching = false;
    public searchFailed = false;
    public hideSearchingWhenUnsubscribed = new Observable(() => () => (this.searching = false));
    public inputSetsObj: ResultSet[] = [];
    public isTelemedActive = false;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public diagnose$: Observable<any[]>;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public selectedDiagnoses: any[] = [];
    public footNote: string;
    public searchInput$: Subject<string> = new Subject<string>();

    private onDestroy$ = new Subject<void>();
    private case: Case;
    public selectedContractorId: number = this.store.selectSnapshot(BaseState.activeContractorId);
    public loggedUser: User = this.store.selectSnapshot(BaseState.LoggedUser);

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public tmceSettings: any = {
        menubar: false,
        statusbar: true,
        resize: true,
        toolbar: 'bold | italic | ',
        plugins: 'paste',
        placeholder: 'Zdravila predpisana stranki',
    };

    constructor(
        public activeModal: NgbActiveModal,
        private rest: ResultService,
        // private storage: StorageService,
        private toast: MfToastService,
        public comm: PfPCommnComunicationService,
        public helper: HelperService,
        private reservationRest: ReservationService,
        private commonRest: CommonService,
        public store: Store,
        private am: ActiveModulesService,
    ) {
        super(helper);
    }

    ngOnInit() {
        if (this.isNew) {
            this.createReport();
        } else {
            this.loadData(this.paper.visitId, this.paper.id);
        }
        this.checkForTelemed();
        this.communications();
        this.initSearch();
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngOnDestroy(): void {
        this.comm.dirty$.next(false);
        this.onDestroy$.next();
    }

    private loadData(visitId: number, paperId: number): void {
        this.rest
            .getSurveyPaper(visitId, paperId)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(res => {
                if (_.get(res, 'surveys', []).length > 0) {
                    this.inputSetsObj = res.surveys;
                    this.setPlaceholders();
                }
                if (_.get(res, 'diagnoses')) {
                    this.selectedDiagnoses = res.diagnoses;
                }
                if (_.get(res, 'footNote')) {
                    this.footNote = res.footNote;
                }
            });
    }

    private initSearch(): void {
        this.diagnose$ = concat(
            of([]), // default items
            this.searchInput$.pipe(
                debounceTime(200),
                distinctUntilChanged(),
                tap(() => (this.searching = true)),
                switchMap(term =>
                    this.commonRest.searchDiagnose(term).pipe(
                        map(data => {
                            return data.map(el => ({
                                ...el,
                                fullValue: `${el.name}  (${el.code})`,
                            }));
                        }),
                        catchError(() => of([])), // empty list on error
                        tap(() => (this.searching = false)),
                    ),
                ),
            ),
        );
    }

    public checkForTelemed() {
        //TODO
        this.reservationRest
            .getReservation(this.selectedContractorId, this.prereservationId)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(data => {
                this.isTelemedActive = data.telemed;
            });
    }
    public setPlaceholders() {
        this.inputSetsObj.forEach(survey => {
            switch (survey.title) {
                case 'Anamneza':
                    survey.placeholder = 'Subjektivni podatki, opis težav (klienta, stranke)';
                    break;
                case 'Status':
                    survey.placeholder = 'Objektivne najdbe, podatki, ugotovitve, rezultati';
                    break;
                case 'Mnenje':
                    survey.placeholder = 'Delovna diagnoza, ocena razlogi za obisk, mnenje zdravnika, strokovno mnenje';
                    break;
                case 'Načrt nadaljnje obravnave':
                    survey.placeholder = 'Nasveti stranki, strokovna navodila (za potrditev diagnoze, za zdravljenje, predpis zdravljenja)';
                    break;
                case 'Zdravila':
                    survey.placeholder = 'Zdravila predpisana stranki';
                    break;
            }
        });
    }

    private communications(): void {
        this.comm.onCopy.pipe(takeUntil(this.onDestroy$)).subscribe((data: PaperHistoryElement) => {
            this.copyFromHistory(data);
        });

        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.comm.onSave.pipe(takeUntil(this.onDestroy$)).subscribe((data: boolean) => {
            this.saveSurvey().subscribe();
        });

        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.comm.onPreview.pipe(takeUntil(this.onDestroy$)).subscribe((data: boolean) => {
            this.openPreview(this.paper, false);
        });

        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.comm.onPrint.pipe(takeUntil(this.onDestroy$)).subscribe((data: boolean) => {
            this.openPreview(this.paper, true);
        });

        this.comm.onSignDocument.pipe(takeUntil(this.onDestroy$)).subscribe(data => {
            this.comm.saveSignShareAndShow(
                this.paper,
                data,
                () => this.saveSurvey(),
                casee => (this.case = casee),
                true,
                this.am.isAM(AppModulesType.HELLO_DOCTOR),
                this.bsType === BsType.PREVENTIVNI,
            );
        });
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.comm.onTemplateSaveSurvey.pipe(takeUntil(this.onDestroy$)).subscribe((data: any) => {
            if (!data.name) {
                this.saveTemplate(data).subscribe(result => {
                    this.comm.onTemplateSaveSurvey.next({ id: result, name: data });
                });
            }
        });
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.comm.onTemplateSelect.pipe(takeUntil(this.onDestroy$)).subscribe((data: any) => {
            super.selectTemplate(data, 'survey', this.inputSetsObj);
            this.comm.dirty$.next(true);
        });
        this.comm.resetValues.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
            this.inputSetsObj = [];
            this.createReport();
        });
    }

    private copyFromHistory(data: PaperHistoryElement): void {
        this.comm.dirty$.next(true);
        this.loadData(data.visitId, data.paperId);
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public saveTemplate(name: string): Observable<any> {
        return this.rest.saveDocumentTemplate(
            this.selectedContractorId,
            name,
            new Result().deserialize({
                surveys: this.inputSetsObj,
                surveyType: PaperType.MFMEDICAL_IZVID,
            }),
        );
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private saveSurvey(): Observable<any> {
        if (this.isNew) {
            let result;
            // TODO Ignored with eslint-interactive on 2023-11-10
            // eslint-disable-next-line prefer-const
            result = new Result().deserialize({
                surveys: this.inputSetsObj,
                surveyType: PaperType.MFMEDICAL_IZVID,
                logo: LogoType.MEDIFIT,
                diagnoses: this.selectedDiagnoses.map(diag => {
                    if (!diag.name) {
                        diag.name = diag.fullValue;
                    }
                    return diag;
                }),
                footNote: this.footNote,
            });

            return this.rest.addSurvey(this.paper.visitId, result).pipe(
                takeUntil(this.onDestroy$),
                tap(
                    res => {
                        this.paper = new PortfolioPaper().deserialize({
                            id: res,
                            visitId: this.paper.visitId,
                            paperType: PaperType.MFMEDICAL_IZVID,
                        });
                        this.comm.onPaperChanged.emit(this.paper);

                        this.toast.success('Spremembe so shranjene.');
                        this.isNew = false;
                        this.comm.dirty$.next(false);
                    },
                    () => {
                        if (this.paper.visitId) {
                            this.toast.errorAndSend('Napaka pri dodajanju novega sklopa.');
                        } else {
                            this.toast.errorAndSend('Kreiranje listine ni mogoče, obravnava ni ustvarjena.');
                        }
                    },
                ),
            );
        } else {
            //update
            return this.rest
                .updateSurveyPaper(
                    this.paper.visitId,
                    this.paper.id,
                    new Result().deserialize({
                        surveys: this.inputSetsObj,
                        surveyType: PaperType.MFMEDICAL_IZVID,
                        // logo: this.isTelemedActive ? LogoType.HELLODOC : LogoType.MEDIFIT,
                        logo: LogoType.MEDIFIT,
                        diagnoses: this.selectedDiagnoses
                            ? this.selectedDiagnoses.map(diag => {
                                  if (!diag.name) {
                                      diag.name = diag.fullValue;
                                  }
                                  return diag;
                              })
                            : this.selectedDiagnoses,
                        footNote: this.footNote,
                    }),
                )
                .pipe(
                    takeUntil(this.onDestroy$),
                    tap(
                        () => {
                            this.toast.success('Spremembe so shranjene.');
                            this.comm.dirty$.next(false);
                        },
                        () => this.toast.errorAndSend('Napaka pri urejanju sklopa.'),
                    ),
                );
        }
    }

    public openSignedPreview(paper: PortfolioPaper): void {
        this.comm
            .openSignedPreviewAppend(paper, this.case, 'Medifit_Medical_Izvid')
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
                () => {
                    this.toast.success('Dokument dodan k avtorizaciji');
                },
                data => {
                    if (data !== 'close-no-error') {
                        this.toast.error('Napaka pri dodajanju dokumenta k avtorizaciji');
                    }
                },
            );
    }

    public openPreview(paper: PortfolioPaper, print: boolean = false): void {
        this.rest
            .getSurveyPaperPreview(this.paper.visitId, paper.id)
            .pipe(
                takeUntil(this.onDestroy$),
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                map((res: any) => {
                    if (res.type !== 0) {
                        return new Blob([res.body], {
                            type: 'application/pdf',
                        });
                    }
                }),
            )
            .subscribe(
                res => {
                    if (res) {
                        this.helper.openDocumentViewer(res, res.type, 'Medifit_Medical_Izvid', print);
                    }
                },
                () => {
                    this.toast.errorAndSend('Napaka pri predogledu poročila.');
                },
            );
    }

    private createReport(): void {
        this.inputSetsObj.push(
            new ResultSet().deserialize({
                title: 'Združen opis',
                surveyId: '-1',
                createdBy: this.loggedUser.name,
                sortId: 1,
            }),
        );
        this.inputSetsObj.push(
            new ResultSet().deserialize({
                title: 'Anamneza',
                surveyId: '-1',
                createdBy: this.loggedUser.name,
                sortId: 2,
            }),
        );
        this.inputSetsObj.push(
            new ResultSet().deserialize({
                title: 'Status',
                surveyId: '-1',
                createdBy: this.loggedUser.name,
                sortId: 3,
            }),
        );
        this.inputSetsObj.push(
            new ResultSet().deserialize({
                title: 'Mnenje',
                surveyId: '-1',
                createdBy: this.loggedUser.name,
                sortId: 4,
            }),
        );
        this.inputSetsObj.push(
            new ResultSet().deserialize({
                title: 'Načrt nadaljnje obravnave',
                surveyId: '-1',
                createdBy: this.loggedUser.name,
                sortId: 5,
            }),
        );

        this.setPlaceholders();
    }
}
