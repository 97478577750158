import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { CustomerPapersElement } from '../../../../_shared/model';
import { HelperService } from '../../../../_shared/services';
import { HttpClientService } from '../../../../_shared/services/http-client.service';
import { SendInvoiceNotificationInfo } from './../../../../_shared/model/accounting/invoice/sendInvoiceNotificationInfo.model';
import { PartnerVisitRequests } from './../../../../_shared/model/accounting/partners/partner-visit-requests.model';
import { PartnerXmlLog } from './../../../../_shared/model/accounting/partners/partner-xml-log';
import { VisitNew } from './../../../../_shared/model/schedule/visitNew.model';
import { Visit } from './../../../../_shared/model/visit.model';

@Injectable()
export class VisitService {
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public NotifyVisitIsConcluded: Subject<any> = new Subject();

    constructor(
        private httpClient: HttpClientService,
        private helper: HelperService,
    ) {}

    public getOrderFormTreatments(groupId: number) {
        // groupId 62 -> laboratorijske preiskave
        // groupId 59 -> diagnostične preiskave
        return this.httpClient.get(this.helper.getApiUrl(2) + `/lab/services/groups/${groupId}`);
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public creatPdfForVisitPaper(visitId: string, data: any): Observable<any> {
        return this.httpClient.getAttachmentPost(this.helper.getPrintServerUrl() + `/visits/${visitId}/termin`, data);
    }

    //createVisitFromPrereservation
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public createVisitFromPrereservation(prereservationId: string, customerId?: string | number): Observable<any> {
        let url = this.helper.getVisitUrl() + '/visits/' + prereservationId;
        if (customerId) {
            url += '/customer/' + customerId;
        }
        return this.httpClient.post(url);
    }

    // concludeVisit() /contractors/{contractorid}/visits/{visitid}
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public concludeVisit(contractorId: number, visitid: number, data?): Observable<any> {
        return this.httpClient.put(this.helper.getVisitUrl() + '/contractors/' + contractorId + '/visits/' + visitid, data);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getVisitsForContactor(contractorId: number, from: string, to: string): Observable<any> {
        return this.httpClient.post(this.helper.getVisitUrl(2) + '/contractors/' + contractorId + '/visits', {
            dateFrom: from,
            dateTo: to,
        });
    }
    //isVisitConcluded()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public isVisitConcluded(contractorId: number, visitid: number): Observable<any> {
        return this.httpClient.get(this.helper.getVisitUrl() + '/contractors/' + contractorId + '/visits/' + visitid + '/concluded');
    }
    //createVisit()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public createVisit(data: VisitNew): Observable<any> {
        // let data = { contractor: contractor, customer: customer, visitTime: visitTime, services: services };
        return this.httpClient.post(this.helper.getVisitUrl() + '/visits', data);
    }

    //getVisit()
    public getVisit(contractorId: number, visitid: string | number): Observable<Visit> {
        return this.httpClient.get(this.helper.getVisitUrl(2) + '/contractors/' + contractorId + '/visits/' + visitid);
    }

    //getVisit()
    public getVisitFromPrereservation(contractorId: number, prereservationid: string): Observable<Visit> {
        return this.httpClient.get(this.helper.getVisitUrl() + `/contractors/${contractorId}/visits/prereservations/${prereservationid}`);
    }

    //createVisitFromPrereservation
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public deleteVisit(visitId: string | number): Observable<any> {
        return this.httpClient.delete(this.helper.getVisitUrl() + '/visits/' + visitId);
    }

    //sendDiagnose()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public sendResult(visitId: string): Observable<any> {
        return this.httpClient.post(this.helper.getVisitUrl() + '/visits/' + visitId + '/diagnosis/mail');
    }

    // public sendPaper(visitId: string, paperid: number, data: SendInvoiceNotificationInfo): Observable<any> {
    //DEPRICATED
    //     return this.httpClient.post(this.helper.getVisitUrl() + `/visits/${visitId}/paper/mail/${paperid}`, data);
    // }
    // public sendPaper(term: any, paperid: number, data: SendInvoiceNotificationInfo): Observable<any> {
    //     if (term.visitId) {
    //         return this.httpClient.post(
    //             this.helper.getVisitUrl(2) + `/visits/${term.visitId}/paper/mail/${paperid}`,
    //             data,
    //         );
    //     } else {
    //         return this.httpClient.post(
    //             this.helper.getApiUrl(2) + `/reservations/${term.prereservationId}/paper/mail/${paperid}`,
    //             data,
    //         );
    //     }
    // }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public sendPaperPrereservation(prereservationId: any, paperid: number, data: SendInvoiceNotificationInfo): Observable<any> {
        return this.httpClient.post(this.helper.getApiUrl(2) + `/reservations/${prereservationId}/paper/mail/${paperid}`, data);
    }

    // public shareDocument(visitId: number, paperId: number, data: SendInvoiceNotificationInfo): Observable<any> {
    //     return this.httpClient.post(
    //         this.helper.getVisitUrl(2) + `/visits/${visitId}/paper/${paperId}/mailSharedPaper`,
    //         data,
    //     );
    // }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public stopShareDocument(term: any, paperId: number): Observable<any> {
        return this.httpClient.put(this.helper.getVisitUrl(2) + `/visits/${term.visitId}/paper/${paperId}/unshare`);
    }

    //getCustomerListsHistory()
    public getCustomerListsHistory(contractorId: number, customerId: number, paperType?): Observable<CustomerPapersElement[]> {
        if (paperType) {
            return this.httpClient.get(this.helper.getApiUrl(2) + `/contractors/${contractorId}/customers/${customerId}/papers/${paperType}`);
        } else {
            return this.httpClient.get(this.helper.getApiUrl(2) + `/contractors/${contractorId}/customers/${customerId}/papers`);
        }
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getVisitInformation(contractorId, visitid): Observable<any> {
        return this.httpClient.get(this.helper.getVisitUrl(2) + `/contractors/${contractorId}/visits/${visitid}`);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public ignoreUnprocessedInvoice(contractorId: number, visitId: number, ignoreInvoice: boolean): Observable<any> {
        return this.httpClient.put(
            this.helper.getVisitUrl(2) + `/contractors/${contractorId}/visits/${visitId}/ignoreUnprocessedInvoice/${ignoreInvoice}`,
        );
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public connectInvoiceWithVisit(contractorId: number, visitId: number, invoiceId: number): Observable<any> {
        return this.httpClient.put(
            this.helper.getVisitUrl(2) + `/contractors/${contractorId}/visits/${visitId}/invoices/${invoiceId}/connect`,
            undefined,
        );
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public disconnectInvoiceWithVisit(contractorId: number, visitId: number, invoiceId: number): Observable<any> {
        return this.httpClient.put(
            this.helper.getVisitUrl(2) + `/contractors/${contractorId}/visits/${visitId}/invoices/${invoiceId}/disconnect`,
            undefined,
        );
    }

    public getPartnerBsVisits(contractorId: number, bsid: number, year: number, month: number): Observable<PartnerVisitRequests> {
        return this.httpClient.get(
            this.helper.getErpRootUrl('bsapi') + `/contractors/${contractorId}/bs/${bsid}/years/${year}/months/${month}/preview`,
        );
    }

    public getContractorSendLogXml(contractorId: number): Observable<PartnerXmlLog[]> {
        return this.httpClient.get(this.helper.getErpRootUrl('bsapi') + `/contractors/${contractorId}/xml/log`);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public connectVisitWithCase(contractorId: number, visitId: number, bsId: number, caseId: string): Observable<any> {
        return this.httpClient.put(
            this.helper.getVisitUrl(2) +
                `/contractors/${contractorId}/visits/${visitId}/connect/bs/${bsId}/case/${this.helper.replaceSlahsForDolar(caseId)}`,
        );
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public disconnectVisitWithCase(contractorId: number, visitId: number, bsId: number, caseId: string): Observable<any> {
        return this.httpClient.delete(
            this.helper.getVisitUrl(2) +
                `/contractors/${contractorId}/visits/${visitId}/connect/bs/${bsId}/case/${this.helper.replaceSlahsForDolar(caseId)}`,
        );
    }

    public createInvoiceForXml(contractorId: number, bsId: number, xmlId: string, id: number): Observable<PartnerXmlLog[]> {
        return this.httpClient.post(this.helper.getErpRootUrl('bsapi') + `/contractors/${contractorId}/bs/${bsId}/invoice/${xmlId}/id/${id}`);
    }

    public confirmXml(contractorId: number, bsId: number, xmlId: string, id: number): Observable<PartnerXmlLog[]> {
        return this.httpClient.post(this.helper.getErpRootUrl('bsapi') + `/contractors/${contractorId}/bs/${bsId}/invoice/${xmlId}/id/${id}/confirm`);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getSendXml(contractorId: number, bsId: number, invoiceNo: string, id: number): Observable<any> {
        return this.httpClient.get(this.helper.getErpRootUrl('bsapi') + `/contractors/${contractorId}/bs/${bsId}/invoice/${invoiceNo}/id/${id}`);
    }

    // curl --location --request GET 'http://localhost:9190/booking/eav2/erp/bsapi/contractors/54/bs/7/invoiceNo/VZA-000000641-1631858939' \

    // http://10.230.33.13:9190/booking/eav2/erp/bsapi/contractors/54/bs/1/invoice/GEN-zbtVW8PgXSoJsr9Y0OuqwfWmmfA/confirm
    // http://localhost:9190/booking/eav2/erp/visitapi/contractors/54/visits/45172/connect/bs/1/case/testis_c1
}
