import { Injectable } from '@angular/core';
import _ from 'lodash';
import { Observable, of } from 'rxjs';
import { VendorType } from '../../enums/vendor/vendor-type.enum';
import { DeviceObservationResponseDto } from '../../model/device/device-observation-response-dto';
import { DeviceResponseDto } from '../../model/device/device-response-dto';
import { LabisExaminationRequestDto } from '../../model/laboratory/lab-examination-request-dto';
import { LabDetailsResponseDto } from '../../model/laboratory/lab-observation-response-dto';
import { LabOrderTemplate } from '../../model/laboratory/lab-order-template-dto';
import { ContractorResponseDto } from '../../model/vendor/contractor-response-dto';
import { HelperService } from '../helper.service';
import { HttpClientService } from '../http-client.service';
import { StorageService } from '../storage.service';

@Injectable({
    providedIn: 'root',
})
export class VendorClientService {
    constructor(
        private httpClient: HttpClientService,
        private helper: HelperService,
        private storage: StorageService,
    ) {}

    public getContractor(contractorId: number, vendorType: VendorType): Observable<ContractorResponseDto[]> {
        return this.httpClient.get(this.helper.getLaboratoryApiUrl(`/contractors/${contractorId}/vendorContractors/${vendorType}`));
    }

    public getContractorDevices(contractorId: number, vendorContractorId: number): Observable<DeviceResponseDto[]> {
        return this.httpClient.get(this.helper.getLaboratoryApiUrl(`/contractors/${contractorId}/vendorContractors/${vendorContractorId}/devices`));
    }

    public getDeviceObservations(contractorId: number, vendorContractorId: number, deviceId: string): Observable<DeviceObservationResponseDto[]> {
        return this.httpClient.get(
            this.helper.getLaboratoryApiUrl(`/contractors/${contractorId}/vendorContractors/${vendorContractorId}/devices/${deviceId}/observations`),
        );
    }

    public getLaboratoryDetails(contractorId: number, labId: number): Observable<LabDetailsResponseDto[]> {
        return this.httpClient.get(this.helper.getLaboratoryApiUrl(`/contractors/${contractorId}/vendorContractors/${labId}/observations`));
    }

    public createLabisReservationExamination(
        contractorId: number,
        prereservationId: string,
        request: LabisExaminationRequestDto,
    ): Observable<number> {
        return this.httpClient.post(
            this.helper.getLaboratoryApiUrl(`/contractors/${contractorId}/reservations/${prereservationId}/examinations/labis`),
            request,
        );
    }

    public getLabOrderTemplates(contractorId: number, labId: number): Observable<LabOrderTemplate[]> {
        const key = this.generateStorageKey(contractorId, labId);
        try {
            const storedData = this.storage.getLabisTemplates();
            const templates = storedData ? JSON.parse(storedData)[key] || [] : [];
            return of(templates);
        } catch {
            return of([] as unknown as LabOrderTemplate[]);
        }
    }

    public saveLabOrderTemplate(
        contractorId: number,
        labId: number,
        templateId: number = null,
        template: LabOrderTemplate,
    ): Observable<LabOrderTemplate[]> {
        const key = this.generateStorageKey(contractorId, labId);
        const storedData = JSON.parse(this.storage.getLabisTemplates()) || {};
        let templates = (storedData[key] as LabOrderTemplate[]) || [];

        if (templateId) {
            const copy = _.cloneDeep(templates);
            const match = copy.find(template => template.id === templateId);
            if (match) {
                match.data.observationIds = template.data.observationIds;
                templates = [...copy];
            }
        } else {
            template.id = Math.random() * Math.pow(2, 32);
            templates = [...(templates || []), template];
        }

        storedData[key] = templates;
        this.storage.setLabisTemplates(JSON.stringify(storedData));
        return of(templates);
    }

    public deleteLabOrderTemplate(contractorId: number, labId: number, templateId: number): Observable<unknown> {
        const key = this.generateStorageKey(contractorId, labId);
        const storedData = JSON.parse(this.storage.getLabisTemplates()) || {};
        let templates = (storedData[key] as LabOrderTemplate[]) || [];
        templates = [...templates.filter(template => template.id !== templateId)];

        storedData[key] = templates;
        this.storage.setLabisTemplates(JSON.stringify(storedData));
        return of([...(templates || [])]);
    }

    private generateStorageKey(contractorId: number, labId: number): string {
        return contractorId.toString().concat(labId.toString());
    }
}
