import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import 'rxjs';
import { Observable } from 'rxjs';
import { HelperService } from '../../../../_shared/services';
import { HttpClientService } from '../../../../_shared/services/http-client.service';
import { BaseState } from '../../../../_shared/state/base/base.state';
import { DocumentCancelRequest, DocumentShareRequest } from '../terms/visit/crpp/_model/crpp-document-request';
import { DocumentResponse } from '../terms/visit/crpp/_model/crpp-document-response';

@Injectable()
export class CrppDocumentsService {
    private contractorId: number = this.store.selectSnapshot(BaseState.activeContractorId);

    constructor(
        private httpClient: HttpClientService,
        private helper: HelperService,
        private store: Store,
    ) {}

    public storeDocument(data: DocumentShareRequest): Observable<DocumentResponse> {
        return this.httpClient.post(this.helper.getCrppUrl() + `/contractors/${this.contractorId}/papers/save`, data);
    }

    public cancelDocument(data: DocumentCancelRequest): Observable<DocumentResponse> {
        return this.httpClient.post(this.helper.getCrppUrl() + `/contractors/${this.contractorId}/papers/cancel`, data);
    }
}
