import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import _ from 'lodash';
import * as moment from 'moment/moment';
import { concat, EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AppModulesType } from '../../../../../../_shared/enums/appModulesType.enum';
import { BsType } from '../../../../../../_shared/enums/business.enum';
import { PaperType } from '../../../../../../_shared/enums/PaperType.enum';
import { Event } from '../../../../../../_shared/model';
import { GenericModuleSettings } from '../../../../../../_shared/model/app/genericModuleSettings';
import { Module } from '../../../../../../_shared/model/app/module.model';
import { MedicalDocumentationModel } from '../../../../../../_shared/model/medical/medicalDocumentation.model';
import { PaperHistoryElement } from '../../../../../../_shared/model/portfolio/paperHistoryElement.model';
import { PortfolioPaper } from '../../../../../../_shared/model/portfolio/portfolioPaper.model';
import { Result } from '../../../../../../_shared/model/result.model';
import { Case } from '../../../../../../_shared/model/schedule/case.model';
import { StorageService } from '../../../../../../_shared/services';
import { ActiveModulesService } from '../../../../../../_shared/services/activeModules.service';
import { HelperMedicalService } from '../../../../../../_shared/services/helper-medical.service';
import { HelperService } from '../../../../../../_shared/services/helper.service';
import { MedicalService } from '../../../../../../_shared/services/medical.service';
import { MfToastService } from '../../../../../../_shared/services/mfToast.service';
import { ResultService } from '../../../_services/result.service';
import { PfPBaseComponent } from '../pf-p-base/pf-p-base.component';
import { PfPCommnComunicationService } from '../_common/pf-p-comn-communication.service';
import { LogoType } from './../../../../../../_shared/enums/logoType.enum';
import { User } from './../../../../../../_shared/model/user.model';
import { BaseState } from './../../../../../../_shared/state/base/base.state';
import { CommonService } from './../../../_services/common.service';
import { VisitType } from './enum/visit-type';

@Component({
    selector: 'pf-p-mfm-report',
    templateUrl: './pf-p-mfm-report.component.html',
    styles: [],
})
export class PfPMfmReportComponent extends PfPBaseComponent implements OnInit {
    @Input()
    isNew = false;

    @Input()
    paper: PortfolioPaper;

    @Input()
    prereservationId: string;

    @Input()
    termData: Event;

    @ViewChildren(NgForm) formList: QueryList<NgForm>;

    public searching = false;
    public searchFailed = false;
    public hideSearchingWhenUnsubscribed = new Observable(() => () => (this.searching = false));
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public diagnose$: Observable<any[]>;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public selectedDiagnoses: any[] = [];
    public selectedMedicalCareType: string;
    public searchInput$: Subject<string> = new Subject<string>();

    public isReportClaim;
    public notRequired = false;
    public skipSavingMedicalDocumentation = false;

    sign = false;

    private onDestroy$ = new Subject<void>();
    private case: Case;
    public selectedContractorId: number = this.store.selectSnapshot(BaseState.activeContractorId);
    public loggedUser: User = this.store.selectSnapshot(BaseState.LoggedUser);

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public calendarOptions: any = {};

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public tmceSettings: any = {
        menubar: false,
        branding: false,
        statusbar: true,
        resize: true,
        toolbar: 'bold | italic | fullscreen',
        plugins: 'paste, fullscreen',
        paste_as_text: true,
        entity_encoding: 'raw',
        encoding: 'xml',
        setup: editor => {
            editor.on('Load', () => {
                editor.settings.placeholder = this.getPlaceholder(editor.id);
            });
        },
    };

    public inputObj = {
        medicalCareType: {
            title: 'Tip poškodbe oziroma bolezni',
            content: null,
            surveyId: 'medicalCareType',
            sortId: '0',
            placeholder: undefined,
        },
        anamnesis: {
            title: 'Anamneza',
            content: null,
            surveyId: 'anamnesis',
            sortId: '1',
            placeholder: 'Vnos anamneze',
        },
        status: {
            title: 'Status',
            content: null,
            surveyId: 'status',
            sortId: '2',
            placeholder: 'Vnos statusa',
        },
        pastMedicalConditions: {
            title: 'Dosedanje zdravljenje',
            content: null,
            surveyId: 'pastMedicalConditions',
            sortId: '3',
            placeholder: undefined,
        },
        pastMedicalConditionsDescription: {
            title: 'Opis',
            content: null,
            surveyId: 'pastMedicalConditionsDescription',
            sortId: '4',
            placeholder: 'Kje in kdaj se je stranka zdravila. Če jemlje zdravila, navedite, katera.',
        },
        accidentDate: {
            title: 'Datum nastale poškodbe ali bolezni',
            content: null,
            surveyId: 'accidentDate',
            sortId: '5',
            placeholder: undefined,
        },
        opinionAndFurtherTreatment: {
            title: 'Mnenje in nadaljnja obravnava',
            content: null,
            surveyId: 'opinionAndFurtherTreatment',
            sortId: '6',
            placeholder: 'Opis',
        },
        medicalOperationNeeded: {
            title: 'Napotena storitev',
            content: null,
            surveyId: 'medicalOperationNeeded',
            sortId: '7',
            placeholder: 'Vnos napotene storitve',
        },
    };

    private getPlaceholder(id: string): string | null {
        const item = Object.values(this.inputObj).find(obj => obj.surveyId === id);
        if (item) {
            return item.placeholder || null;
        }
        return null;
    }

    private DateRangeSettings(startDate: moment.Moment = moment().subtract(1, 'day'), endDate: moment.Moment = moment()) {
        return Object.assign({
            autoApply: true,
            parentEl: '#modalPopup',
            showDropdowns: true,
            locale: this.dateSelectorLocale,
            alwaysShowCalendars: true,
            linkedCalendars: false,
            maxDate: moment(),
            ranges: {
                Danes: [moment(), moment()],
                Včeraj: [moment().subtract(1, 'day'), moment().subtract(1, 'day')],
            },
            startDate: startDate,
            endDate: endDate,
        });
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/no-explicit-any
    private dateSelectorLocale: any = {
        format: 'DD.MM.YYYY',
        cancelLabel: 'Prekliči',
        applyLabel: 'Potrdi',
        customRangeLabel: 'Izberi ročno',
        daysOfWeek: ['Ne', 'Po', 'To', 'Sr', 'Če', 'Pe', 'So'],
        monthNames: ['Januar', 'Februar', 'Marec', 'April', 'Maj', 'Junij', 'Julij', 'Avgust', 'September', 'Oktober', 'November', 'December'],
        firstDay: 1,
    };

    constructor(
        public activeModal: NgbActiveModal,
        private rest: ResultService,
        private medicalRest: MedicalService,
        private toast: MfToastService,
        public comm: PfPCommnComunicationService,
        public helper: HelperService,
        private commonRest: CommonService,
        public store: Store,
        private storage: StorageService,
        private am: ActiveModulesService,
        private medicalHelper: HelperMedicalService,
    ) {
        super(helper);
        this.calendarOptions = this.DateRangeSettings();
        this.calendarOptions.autoUpdateInput = false;
    }

    ngOnInit() {
        if (!this.isNew) {
            this.loadData(this.paper.visitId, this.paper.id);
        }

        this.isReportClaim = this.store.selectSnapshot(BaseState.GetActiveModule(AppModulesType.EA_REPORT_CLAIM)) as Module<GenericModuleSettings>;
        this.notRequired = this.isReportClaim?.settings?.notRequiredFor?.includes(this.termData?.service?.id);
        this.skipSavingMedicalDocumentation = this.isReportClaim?.settings?.skipForBs?.includes(this.termData?.bsid) ?? false;

        this.communications();
        this.initSearch();
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngOnDestroy(): void {
        this.comm.dirty$.next(false);
        this.onDestroy$.next();
    }

    private loadData(visitId: number, paperId: number): void {
        this.rest
            .getSurveyPaper(visitId, paperId)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(res => {
                if (_.get(res, 'surveys', []).length > 0) {
                    this.inputObj.medicalCareType.content = this.getSurveyContent(res?.surveys, 'medicalCareType');
                    this.inputObj.anamnesis.content = this.getSurveyContent(res?.surveys, 'anamnesis');
                    this.inputObj.status.content = this.getSurveyContent(res?.surveys, 'status');
                    this.inputObj.pastMedicalConditions.content = this.getSurveyContent(res?.surveys, 'pastMedicalConditions');
                    this.inputObj.pastMedicalConditionsDescription.content = this.getSurveyContent(res?.surveys, 'pastMedicalConditionsDescription');
                    this.inputObj.accidentDate.content = this.getSurveyContent(res?.surveys, 'accidentDate');
                    this.inputObj.opinionAndFurtherTreatment.content = this.getSurveyContent(res?.surveys, 'opinionAndFurtherTreatment');
                    this.inputObj.medicalOperationNeeded.content = this.getSurveyContent(res?.surveys, 'medicalOperationNeeded');
                }
                if (_.get(res, 'diagnoses')) {
                    this.selectedDiagnoses = res.diagnoses;
                }

                if (this.inputObj.accidentDate?.content) {
                    const date = this.inputObj.accidentDate.content.split(' - ');
                    const startDate = moment(date[0], 'DD.MM.YYYY');
                    const endDate = date.length > 1 ? moment(date[1], 'DD.MM.YYYY') : startDate;
                    this.calendarOptions = this.DateRangeSettings(startDate, endDate);
                }
            });
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getSurveyContent(surveys: any[], surveyId: string): string {
        const survey = surveys.find(el => el.surveyId === surveyId);
        return survey ? survey.content : '';
    }

    private initSearch(): void {
        this.diagnose$ = concat(
            of([]), // default items
            this.searchInput$.pipe(
                debounceTime(200),
                distinctUntilChanged(),
                tap(() => (this.searching = true)),
                switchMap(term =>
                    this.commonRest.searchDiagnose(term).pipe(
                        map(data => {
                            return data.map(el => ({
                                ...el,
                                fullValue: `${el.name}  (${el.code})`,
                            }));
                        }),
                        catchError(() => of([])), // empty list on error
                        tap(() => (this.searching = false)),
                    ),
                ),
            ),
        );
    }

    private communications(): void {
        this.comm.onCopy.pipe(takeUntil(this.onDestroy$)).subscribe((data: PaperHistoryElement) => {
            this.copyFromHistory(data);
        });

        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.comm.onSave.pipe(takeUntil(this.onDestroy$)).subscribe((data: boolean) => {
            this.saveSurvey().subscribe();
        });

        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.comm.onDelete
            .pipe(
                takeUntil(this.onDestroy$),
                switchMap(() => this.medicalRest.deleteMedicalDocumentation(this.paper?.id)),
            )
            .subscribe();

        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.comm.onPreview.pipe(takeUntil(this.onDestroy$)).subscribe((data: boolean) => {
            this.openPreview(this.paper, false);
        });

        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.comm.onPrint.pipe(takeUntil(this.onDestroy$)).subscribe((data: boolean) => {
            this.openPreview(this.paper, true);
        });

        this.comm.onSignDocumentExecuted
            .pipe(
                takeUntil(this.onDestroy$),
                switchMap(() => {
                    if (this.notRequired) {
                        return of(null);
                    }

                    if (_.get(this.paper, 'id') && !this.skipSavingMedicalDocumentation) {
                        return this.saveMedicalDoc();
                    }
                    return of(null);
                }),
                catchError(err => {
                    console.error('Error while saving reportClaim. ', err);
                    this.toast.errorAndSend('Error.');
                    return EMPTY;
                }),
            )
            .subscribe();

        this.comm.onSignDocument.pipe(takeUntil(this.onDestroy$)).subscribe(data => {
            this.sign = true;
            let allFormsValid = true;

            this.formList.forEach(form => {
                if (!form.valid) {
                    allFormsValid = false;
                }
            });

            if (!allFormsValid) {
                this.toast.errorAndSend('Niste izpolnili vseh obveznih polj.');
                return of({});
            }

            this.comm.saveSignShareAndShow(
                this.paper,
                data,
                () => this.saveSurvey(),
                c => (this.case = c),
                true,
                this.am.isAM(AppModulesType.HELLO_DOCTOR),
                this.bsType === BsType.PREVENTIVNI,
            );
        });
    }

    private copyFromHistory(data: PaperHistoryElement): void {
        this.comm.dirty$.next(true);
        this.loadData(data.visitId, data.paperId);
    }

    public setDate(event): void {
        if (!event?.start?.isValid() || !event?.end?.isValid()) {
            this.inputObj.accidentDate.content = null;
            return;
        }
        if (
            _.get(event, 'end') &&
            event.label !== 'Včeraj' &&
            event.label !== 'Danes' &&
            _.get(event, 'end').format('DD.MM.YYYY') !== _.get(event, 'start').format('DD.MM.YYYY')
        ) {
            this.inputObj.accidentDate.content = `${event.start.format('DD.MM.YYYY')} - ${event.end.format('DD.MM.YYYY')}`;
        } else {
            this.inputObj.accidentDate.content = event.start.format('DD.MM.YYYY');
        }
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private saveSurvey(): Observable<any> {
        if (this.inputObj.pastMedicalConditions.content === 'FALSE' && !_.isNil(this.inputObj.pastMedicalConditionsDescription.content)) {
            this.inputObj.pastMedicalConditionsDescription.content = null;
        }

        const surveyArray = Object.keys(this.inputObj).map(key => ({
            ...this.inputObj[key],
            surveyId: key,
        }));

        if (this.isNew) {
            const result = new Result().deserialize({
                surveys: surveyArray,
                surveyType: PaperType.MEDIFIT_MEDICAL_REPORT,
                logo: LogoType.MEDIFIT,
                diagnoses: this.selectedDiagnoses.map(diag => {
                    if (!diag.name) {
                        diag.name = diag.fullValue;
                    }
                    return diag;
                }),
            });

            return this.rest.addSurvey(this.paper.visitId, result).pipe(
                takeUntil(this.onDestroy$),
                tap(
                    res => {
                        this.paper = new PortfolioPaper().deserialize({
                            id: res,
                            visitId: this.paper.visitId,
                            paperType: PaperType.MEDIFIT_MEDICAL_REPORT,
                        });
                        this.comm.onPaperChanged.emit(this.paper);

                        this.toast.success('Spremembe so shranjene.');
                        this.isNew = false;
                        this.comm.dirty$.next(false);
                    },
                    () => {
                        if (this.paper.visitId) {
                            this.toast.errorAndSend('Napaka pri dodajanju novega sklopa.');
                        } else {
                            this.toast.errorAndSend('Kreiranje listine ni mogoče, obravnava ni ustvarjena.');
                        }
                    },
                ),
            );
        } else {
            //update
            return this.rest
                .updateSurveyPaper(
                    this.paper.visitId,
                    this.paper.id,
                    new Result().deserialize({
                        surveys: surveyArray,
                        surveyType: PaperType.MEDIFIT_MEDICAL_REPORT,
                        logo: LogoType.MEDIFIT,
                        diagnoses: this.selectedDiagnoses
                            ? this.selectedDiagnoses.map(diag => {
                                  if (!diag.name) {
                                      diag.name = diag.fullValue;
                                  }
                                  return diag;
                              })
                            : this.selectedDiagnoses,
                    }),
                )
                .pipe(
                    takeUntil(this.onDestroy$),
                    tap(
                        () => {
                            this.toast.success('Spremembe so shranjene.');
                            this.comm.dirty$.next(false);
                        },
                        () => this.toast.errorAndSend('Napaka pri urejanju sklopa.'),
                    ),
                );
        }
    }

    public openPreview(paper: PortfolioPaper, print: boolean = false): void {
        this.rest
            .getSurveyPaperPreview(this.paper.visitId, paper.id)
            .pipe(
                takeUntil(this.onDestroy$),
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                map((res: any) => {
                    if (res.type !== 0) {
                        return new Blob([res.body], {
                            type: 'application/pdf',
                        });
                    }
                }),
            )
            .subscribe(
                res => {
                    if (res) {
                        this.helper.openDocumentViewer(res, res.type, 'Medifit_Medical_Izvid', print);
                    }
                },
                () => {
                    this.toast.errorAndSend('Napaka pri predogledu poročila.');
                },
            );
    }

    private saveMedicalDoc(): Observable<any> {
        return this.rest.getAllSignedFromEa(this.storage.getSelectedContractor().id, _.get(this.paper, 'id')).pipe(
            switchMap(versions => {
                this.paper.version = versions[0]?.version;

                const visitType: VisitType | null = this.termData?.telemed
                    ? VisitType.VIDEO_CALL
                    : this.termData?.phoneCall
                    ? VisitType.PHONE_CALL
                    : null;

                const data: MedicalDocumentationModel = {
                    customerId: this.termData?.customer?.id,
                    visitId: this.termData?.visitId,
                    portfolioPaperId: this.paper?.id,
                    portfolioPaperVersion: this.paper?.version,
                    medicalHistory: this.medicalHelper.stripTinyMceTags(this.inputObj?.anamnesis?.content),
                    medicalCareType: this.inputObj?.medicalCareType?.content,
                    diagnosisCode: this.selectedDiagnoses?.[0]?.code,
                    diagnosisDescription: this.selectedDiagnoses?.[0]?.name,
                    medicalOperationNeeded: this.medicalHelper.stripTinyMceTags(this.inputObj?.medicalOperationNeeded?.content),
                    pastMedicalConditions: this.inputObj?.pastMedicalConditions?.content,
                    pastMedicalConditionsDescription: this.medicalHelper.stripTinyMceTags(this.inputObj?.pastMedicalConditionsDescription?.content),
                    visitDateTime: moment(this.termData?.start),
                    visitType: visitType,
                    accidentDate: moment(this.inputObj?.accidentDate?.content?.split(' - ')[0], 'DD.MM.YYYY').format('YYYY-MM-DD'),
                };
                return this.medicalRest.setMedicalDocumentation(data);
            }),
            catchError(error => {
                this.toast.errorAndSend('Napaka pri shranjevanju strukturiranih podatkov.');
                return of(error);
            }),
        );
    }
}
