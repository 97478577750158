import { ExaminationViewItem } from '../../../theme/pages/home/terms/visit/selected-term/examinations/model/examination-view-item';
import { ReservationExaminationFieldDto } from '../../model/examinations/reservation-examination-field-dto';

export class SetDefaultState {
    public static readonly type = 'SetDefaultState';
}

export class LoadExaminations {
    public static readonly type = 'LoadExaminations';
}

export class SetPreReservation {
    public static readonly type = 'SetPreReservation';
    constructor(
        public readonly preReservationId: string,
        public readonly contractorId: number,
    ) {}
}

export class SetSelectedExamination {
    public static readonly type = 'SetSelectedExamination';
    constructor(public readonly selectedReservationExaminationId: number) {}
}

export class SaveReservationExaminations {
    public static readonly type = 'SaveReservationExaminations';
    constructor(public readonly items: ExaminationViewItem[]) {}
}

export class SaveSelectedExamination {
    public static readonly type = 'saveSelectedExamination';
    constructor(
        public readonly fieldsWithValues: ReservationExaminationFieldDto[] | null,
        public readonly comment: string | null,
    ) {}
}

export class LoadSelectedExaminationTemplates {
    public static readonly type = 'LoadExaminationTemplates';
}

export class CreateExaminationTemplate {
    public static readonly type = 'CreateExaminationTemplate';
    constructor(
        public readonly templateName: string,
        public readonly fieldsWithValue: ReservationExaminationFieldDto[],
        public readonly examinationId: number,
    ) {}
}

export class DeleteExaminationTemplate {
    public static readonly type = 'DeleteExaminationTemplate';
    constructor(
        public readonly templateId: number,
        public readonly examinationId: number,
    ) {}
}

export class UpdateExaminationTemplate {
    public static readonly type = 'UpdateExaminationTemplate';
    constructor(
        public readonly templateId: number,
        public readonly templateName: string,
        public readonly fieldsWithValue: ReservationExaminationFieldDto[],
        public readonly examinationId: number,
    ) {}
}

export class SetSelectedExaminationTemplate {
    public static readonly type = 'SetSelectedExaminationTemplate';
    constructor(public readonly selectedExaminationTemplateId: number) {}
}

export class SearchDiagnoses {
    public static readonly type = 'SearchDiagnoses';
    constructor(public readonly term: string) {}
}

export class UpdateExaminationDiagnosis {
    public static readonly type = 'UpdateExaminationDiagnosis';
    constructor(public readonly diagnosisCodes: string[]) {}
}
