export class LoadContractorSchiller {
    public static readonly type = 'LoadSchiller';
}

export class LoadContractorDevices {
    public static readonly type = 'LoadVendorDevices';
}

export class SetSelectedContractor {
    public static readonly type = 'SetSelectedContractor';
    constructor(public readonly selectedContractorId: number) {}
}

export class SetSelectedDevice {
    public static readonly type = 'SetSelectedDevice';
    constructor(public readonly selectedDeviceId: string) {}
}

export class SetSelectedDeviceObservation {
    public static readonly type = 'SetSelectedDeviceObservation';
    constructor(public readonly selectedDeviceObservationId: string) {}
}

export class LoadDeviceObservations {
    public static readonly type = 'LoadDeviceObservations';
    constructor(public readonly deviceId: string) {}
}

export class SubmitDeviceOrder {
    public static readonly type = 'SubmitDeviceOrder';
    constructor(
        public readonly contractorId: number,
        public readonly observationIds: string[],
        public readonly preReservationId: string,
        public readonly deviceId: string,
    ) {}
}
