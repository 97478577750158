import { LabOrderTemplate } from '../../model/laboratory/lab-order-template-dto';

export class LoadContractorLabs {
    public static readonly type = 'LoadLabs';
}

export class SetSelectedContractor {
    public static readonly type = 'SetSelectedContractor';
    constructor(public readonly selectedContractorId: number) {}
}

export class SetSelectedLab {
    public static readonly type = 'SetSelectedLab';
    constructor(public readonly selectedLabId: number) {}
}

export class LoadLabDetails {
    public static readonly type = 'LoadLabDetails';
    constructor(public readonly labId: number) {}
}

export class SubmitLabOrder {
    public static readonly type = 'SubmitLabOrder';
    constructor(
        public readonly labId: number,
        public readonly observationIds: string[],
        public readonly preReservationId: string,
    ) {}
}

export class LoadOrderTemplates {
    public static readonly type = 'LoadOrderTemplates';
    constructor(public readonly silent: boolean = null) {}
}

export class SaveOrderTemplate {
    public static readonly type = 'SaveOrderTemplate';
    constructor(
        public readonly templateId: number = null,
        public readonly templateData: LabOrderTemplate,
    ) {}
}

export class DeleteOrderTemplate {
    public static readonly type = 'DeleteOrderTemplate';
    constructor(public readonly templateId: number) {}
}
