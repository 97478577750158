import { MedicalRecord } from '../../model/medical-record.model';

export class LoadPrereservation {
    static readonly type = '[Term] Load Prereservation';
    constructor(public id: string) {}
}

export class LoadVisit {
    static readonly type = '[Term] Load Visit';
    constructor(public id: string) {}
}

export class LoadMedicalRecord {
    static readonly type = '[Term] Load Selected Term Medical Records';
}

export class UpdateMedicalRecord {
    static readonly type = '[Term] Update Medical Records';
    constructor(public payload: MedicalRecord) {}
}

export class UploadMedicalRecordDocument {
    static readonly type = '[Term] Upload Medical Record Document';
    constructor(public payload: { file: File; comment?: string }) {}
}
