import { Injectable } from '@angular/core';
import { ObjectState, StatefullValue } from '@meddev/fe-shared';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { forkJoin, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BasicDocumentViewerSettings } from '../../../theme/basic-components/basic-document-viewer/model/basic-document-viewer-settings';
import { CommonService } from '../../../theme/pages/home/_services';
import { AppModulesType } from '../../enums/appModulesType.enum';
import { ExaminationsManager } from '../../managers/examinations-manager';
import { ExaminationFieldTemplateResponseDto } from '../../model/examinations/examination-field-template-response-dto';
import { ExaminationResponseDto, ReservationExaminationResponseDto } from '../../model/examinations/examination-response-dto';
import { Diagnosis } from '../../model/portfolio/diagnose';
import { ActiveModulesService } from '../../services/activeModules.service';
import { ExaminationsClientService } from '../../services/clients/examinations-client.service';
import { Document } from './../../../_shared/model/document.model';
import {
    CreateExaminationTemplate,
    DeleteExaminationTemplate,
    LoadExaminations,
    LoadSelectedExaminationTemplates,
    SaveReservationExaminations,
    SaveSelectedExamination,
    SearchDiagnoses,
    SetDefaultState,
    SetPreReservation,
    SetSelectedExamination,
    SetSelectedExaminationTemplate,
    UpdateExaminationDiagnosis,
    UpdateExaminationTemplate,
} from './examinations-actions';
import { ExaminationStateModel } from './examinations-state.model';

const EXAMINATIONSSTATE_TOKEN: StateToken<ExaminationStateModel> = new StateToken('examinationstate');

const DEFAULT_STATE: ExaminationStateModel = {
    selectedReservationExaminationId: null,
    contractorId: null,
    preReservationId: null,
    contractorExaminations: [],
    examinationsForReservation: [],
    selectedExaminationFields: null,
    documentViewerDocuments: [],
    mdpsSurveyDocumentViewerSettings: null,
    examinationDocumentViewerSettings: null,
    examinationTemplates: { value: [], state: ObjectState.UNINIT },
    selectedExaminationTemplateId: null,
    diagnoses: { value: [], state: ObjectState.UNINIT },
};

@State<ExaminationStateModel>({
    name: EXAMINATIONSSTATE_TOKEN,
    defaults: DEFAULT_STATE,
    children: [],
})
@Injectable()
export class ExaminationState {
    private examinationsManager: ExaminationsManager;
    constructor(
        private examinationsClientService: ExaminationsClientService,
        private am: ActiveModulesService,
        private store: Store,
        private commonService: CommonService,
    ) {
        this.examinationsManager = new ExaminationsManager(this.examinationsClientService, am);
    }

    @Selector([EXAMINATIONSSTATE_TOKEN])
    public static preReservationId$(state: ExaminationStateModel): string | undefined {
        return state.preReservationId;
    }

    @Selector([EXAMINATIONSSTATE_TOKEN])
    public static contractorId$(state: ExaminationStateModel): number | undefined {
        return state.contractorId;
    }

    @Selector([EXAMINATIONSSTATE_TOKEN])
    public static selectedReservationExaminationId$(state: ExaminationStateModel): number | undefined {
        return state.selectedReservationExaminationId;
    }

    @Selector([EXAMINATIONSSTATE_TOKEN])
    public static selectedReservationExamination$(state: ExaminationStateModel): ReservationExaminationResponseDto | undefined {
        return state.examinationsForReservation?.find(examination => examination?.id === state.selectedReservationExaminationId);
    }

    @Selector([EXAMINATIONSSTATE_TOKEN])
    public static contractorExaminations$(state: ExaminationStateModel): ExaminationResponseDto[] | undefined {
        return state.contractorExaminations;
    }

    @Selector([EXAMINATIONSSTATE_TOKEN])
    public static examinationsForReservation$(state: ExaminationStateModel): ReservationExaminationResponseDto[] | undefined {
        return state.examinationsForReservation;
    }

    @Selector([EXAMINATIONSSTATE_TOKEN])
    public static selectedExaminationFields$(state: ExaminationStateModel): ReservationExaminationResponseDto | undefined {
        return state.selectedExaminationFields;
    }

    @Selector([EXAMINATIONSSTATE_TOKEN])
    public static documentViewerDocuments$(state: ExaminationStateModel): Document[] | undefined {
        return state.documentViewerDocuments;
    }

    @Selector([EXAMINATIONSSTATE_TOKEN])
    public static mdpsSurveyDocumentViewerSettings$(state: ExaminationStateModel): BasicDocumentViewerSettings | undefined {
        return state.mdpsSurveyDocumentViewerSettings;
    }

    @Selector([EXAMINATIONSSTATE_TOKEN])
    public static examinationDocumentViewerSettings$(state: ExaminationStateModel): BasicDocumentViewerSettings | undefined {
        return state.examinationDocumentViewerSettings;
    }

    @Selector([EXAMINATIONSSTATE_TOKEN])
    public static examinationTemplates$(state: ExaminationStateModel): StatefullValue<ExaminationFieldTemplateResponseDto[]> | undefined {
        return state.examinationTemplates;
    }

    @Selector([EXAMINATIONSSTATE_TOKEN])
    public static selectedExaminationTemplateId$(state: ExaminationStateModel): number | undefined {
        return state.selectedExaminationTemplateId;
    }

    @Selector([EXAMINATIONSSTATE_TOKEN])
    public static selectedExaminationTemplate$(state: ExaminationStateModel): ExaminationFieldTemplateResponseDto | undefined {
        return state.examinationTemplates.value.find(template => template.id === state.selectedExaminationTemplateId);
    }

    @Selector([EXAMINATIONSSTATE_TOKEN])
    public static diagnoses$(state: ExaminationStateModel): StatefullValue<Diagnosis[]> | undefined {
        return state.diagnoses;
    }

    @Action(SetDefaultState)
    public ClearState(ctx: StateContext<ExaminationStateModel>) {
        ctx.patchState(DEFAULT_STATE);
    }

    @Action(LoadExaminations)
    public LoadExaminations(ctx: StateContext<ExaminationStateModel>) {
        return forkJoin([
            this.examinationsManager.getContractorExaminations$(ctx.getState().contractorId),
            this.examinationsManager.getReservationExaminations$(ctx.getState().preReservationId, ctx.getState().contractorId),
        ]).pipe(
            tap(([contractorExaminations, examinationsForReservation]) => {
                const documentViewerDocuments = this.am.isAM(AppModulesType.OCCUPATIONAL_MEDICINE)
                    ? examinationsForReservation.map(exam => exam.documents).flat()
                    : [];
                const mdpsSurveyDocumentViewerSettings = this.examinationsManager.createMdpsSurveyViewerSettings(
                    ctx.getState().contractorId,
                    examinationsForReservation,
                );
                ctx.patchState({ contractorExaminations, examinationsForReservation, documentViewerDocuments, mdpsSurveyDocumentViewerSettings });
            }),
        );
    }

    @Action(LoadSelectedExaminationTemplates)
    public LoadSelectedExaminationTemplates(ctx: StateContext<ExaminationStateModel>) {
        const selectedExamination = this.store.selectSnapshot(ExaminationState.selectedReservationExamination$);
        ctx.patchState({ examinationTemplates: { value: [], state: ObjectState.LOADING } });
        return this.examinationsClientService.getExaminationTemplates(ctx.getState().contractorId, selectedExamination.examination.id).pipe(
            catchError(err => {
                ctx.patchState({ examinationTemplates: { value: [], state: ObjectState.ERROR } });
                return throwError(() => err);
            }),
            tap(examinationTemplates => {
                ctx.patchState({ examinationTemplates: { value: examinationTemplates, state: ObjectState.VALID } });
            }),
        );
    }

    @Action(SetSelectedExaminationTemplate)
    public SetSelectedExaminationTemplate(ctx: StateContext<ExaminationStateModel>, action: SetSelectedExaminationTemplate) {
        ctx.patchState({ selectedExaminationTemplateId: action.selectedExaminationTemplateId });
    }

    @Action(SetPreReservation)
    public SetPreReservation(ctx: StateContext<ExaminationStateModel>, action: SetPreReservation): Observable<void> {
        ctx.patchState({ preReservationId: action.preReservationId, contractorId: action.contractorId });
        return ctx.dispatch(new LoadExaminations());
    }

    @Action(SetSelectedExamination)
    public SetSelectedExamination(ctx: StateContext<ExaminationStateModel>, action: SetSelectedExamination) {
        if (action.selectedReservationExaminationId) {
            ctx.patchState({
                selectedReservationExaminationId: action.selectedReservationExaminationId,
            });

            const examinationDocumentViewerSettings = this.examinationsManager.createSelectedExaminationDocumentViewerSettings(
                ctx.getState().preReservationId,
                ctx.getState().contractorId,
                ctx.getState().selectedReservationExaminationId,
                this.store.select(ExaminationState.examinationsForReservation$),
                () => {
                    ctx.dispatch(new LoadExaminations());
                },
            );

            ctx.patchState({
                selectedReservationExaminationId: action.selectedReservationExaminationId,
                selectedExaminationFields: ctx
                    .getState()
                    .examinationsForReservation.find(examination => examination.id === action.selectedReservationExaminationId),
                examinationDocumentViewerSettings: examinationDocumentViewerSettings,
            });
        }
    }

    @Action(SaveReservationExaminations)
    public SaveReservationExaminations(ctx: StateContext<ExaminationStateModel>, action: SaveReservationExaminations) {
        return this.examinationsManager
            .saveReservationExaminations$(
                ctx.getState().examinationsForReservation,
                ctx.getState().preReservationId,
                ctx.getState().contractorId,
                action.items,
            )
            .pipe(tap(() => ctx.dispatch(new LoadExaminations())));
    }

    @Action(SaveSelectedExamination)
    public SaveSelectedExamination(ctx: StateContext<ExaminationStateModel>, action: SaveSelectedExamination) {
        return this.examinationsManager
            .saveSelectedExamination(
                ctx.getState().selectedExaminationFields,
                ctx.getState().preReservationId,
                ctx.getState().contractorId,
                ctx.getState().selectedReservationExaminationId,
                action.fieldsWithValues,
                action.comment,
            )
            .pipe(tap(() => ctx.dispatch(new LoadExaminations())));
    }

    @Action(CreateExaminationTemplate)
    public CreateExaminationTemplate(ctx: StateContext<ExaminationStateModel>, action: CreateExaminationTemplate) {
        return this.examinationsClientService
            .createExaminationTemplate(ctx.getState().contractorId, action.examinationId, action.templateName, action.fieldsWithValue)
            .pipe(tap(() => ctx.dispatch(new LoadSelectedExaminationTemplates())));
    }

    @Action(UpdateExaminationTemplate)
    public UpdateExaminationTemplate(ctx: StateContext<ExaminationStateModel>, action: UpdateExaminationTemplate) {
        return this.examinationsClientService
            .updateExaminationTemplate(
                ctx.getState().contractorId,
                action.examinationId,
                action.templateId,
                action.templateName,
                action.fieldsWithValue,
            )
            .pipe(tap(() => ctx.dispatch(new LoadSelectedExaminationTemplates())));
    }

    @Action(DeleteExaminationTemplate)
    public DeleteExaminationTemplate(ctx: StateContext<ExaminationStateModel>, action: DeleteExaminationTemplate) {
        return this.examinationsClientService
            .deleteExaminationTemplate(ctx.getState().contractorId, action.examinationId, action.templateId)
            .pipe(tap(() => ctx.dispatch(new LoadSelectedExaminationTemplates())));
    }

    @Action(SearchDiagnoses)
    public SearchDiagnoses(ctx: StateContext<ExaminationStateModel>, action: SearchDiagnoses) {
        return this.commonService.searchDiagnose(action.term).pipe(
            tap(data => {
                const diagnoses = data.map(el => ({
                    ...el,
                    fullValue: `${el.name}  (${el.code})`,
                }));
                ctx.patchState({ diagnoses: { value: diagnoses, state: ObjectState.VALID } });
            }),
            catchError(err => {
                ctx.patchState({ diagnoses: { value: [], state: ObjectState.ERROR } });
                return throwError(() => err);
            }),
            tap(res => {
                ctx.patchState({ diagnoses: { value: res, state: ObjectState.VALID } });
            }),
        );
    }

    @Action(UpdateExaminationDiagnosis)
    public UpdateExaminationDiagnosis(ctx: StateContext<ExaminationStateModel>, action: UpdateExaminationDiagnosis) {
        return this.examinationsClientService
            .updateExaminationDiagnosis(
                ctx.getState().contractorId,
                ctx.getState().preReservationId,
                ctx.getState().selectedReservationExaminationId,
                action.diagnosisCodes,
            )
            .pipe(tap(() => ctx.dispatch(new LoadExaminations())));
    }
}
