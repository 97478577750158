import { HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ObjectState } from '@meddev/fe-shared';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { catchError, combineLatest, filter, Observable, of, switchMap, tap, throwError, withLatestFrom } from 'rxjs';
import { DocumentsService, VisitService } from '../../../theme/pages/home/_services';
import { TermsService } from '../../../theme/pages/home/_services/terms.service';
import { Event, Visit } from '../../model';
import { HelperService, MfToastService } from '../../services';
import { BaseState } from '../base/base.state';
import { TermStateModel } from './term-state.model';
import { LoadMedicalRecord, LoadPrereservation, LoadVisit, UpdateMedicalRecord, UploadMedicalRecordDocument } from './term.actions';

const TERM_STATE_TOKEN: StateToken<TermStateModel> = new StateToken('termstate');

const DEFAULT_STATE: TermStateModel = {
    term: {
        state: ObjectState.UNINIT,
        value: null,
    },
    medicalRecord: {
        state: ObjectState.UNINIT,
        value: null,
    },
};

@State<TermStateModel>({
    name: TERM_STATE_TOKEN,
    defaults: DEFAULT_STATE,
    children: [],
})
@Injectable({
    providedIn: 'root',
})
export class TermState {
    constructor(
        private termsService: TermsService,
        private visitService: VisitService,
        private documentsService: DocumentsService,
        private helper: HelperService,
        private mfToast: MfToastService,
        private store: Store,
    ) {}

    @Selector()
    static term(state: TermStateModel) {
        return state.term;
    }

    @Selector()
    static termId(state: TermStateModel) {
        return state.term.value?.prereservationId;
    }

    @Selector()
    static medicalRecord(state: TermStateModel) {
        return state.medicalRecord;
    }

    @Action(LoadPrereservation)
    loadPrereservation({ patchState }: StateContext<TermStateModel>, { id }: LoadPrereservation): Observable<Event> {
        patchState({
            term: {
                state: ObjectState.LOADING,
                value: null,
            },
        });

        return this.store.selectOnce(BaseState.activeContractorId).pipe(
            switchMap(contractorId => this.termsService.getReservation(contractorId, id)),
            catchError(err => {
                patchState({
                    term: {
                        state: ObjectState.ERROR,
                        value: null,
                    },
                });
                return throwError(() => err);
            }),
            tap(res => {
                patchState({
                    term: {
                        state: ObjectState.VALID,
                        value: new Event().deserialize(res),
                    },
                });
            }),
        );
    }

    @Action(LoadVisit)
    loadVisit({ patchState }: StateContext<TermStateModel>, { id }: LoadVisit): Observable<Visit> {
        patchState({
            term: {
                state: ObjectState.LOADING,
                value: null,
            },
        });

        return this.store.selectOnce(BaseState.activeContractorId).pipe(
            switchMap(contractorId => this.visitService.getVisit(contractorId, id)),
            catchError(err => {
                patchState({
                    term: {
                        state: ObjectState.ERROR,
                        value: null,
                    },
                });
                return throwError(() => err);
            }),
            tap(res => {
                patchState({
                    term: {
                        state: ObjectState.VALID,
                        value: new Event().deserialize(res),
                    },
                });
            }),
        );
    }

    @Action(LoadMedicalRecord)
    loadMedicalRecord({ patchState }: StateContext<TermStateModel>) {
        patchState({
            medicalRecord: {
                state: ObjectState.LOADING,
                value: null,
            },
        });

        return combineLatest([this.store.selectOnce(BaseState.activeContractorId), this.store.selectOnce(TermState.termId)]).pipe(
            switchMap(([contractorId, id]) => this.termsService.getReservationMedicalRecord(id, contractorId)),
            catchError(err => {
                patchState({
                    medicalRecord: {
                        state: ObjectState.ERROR,
                        value: null,
                    },
                });
                return throwError(() => err);
            }),
            tap(res => {
                patchState({
                    medicalRecord: {
                        state: ObjectState.VALID,
                        value: res,
                    },
                });
            }),
        );
    }

    @Action(UpdateMedicalRecord)
    updateMedicalRecord({ patchState }: StateContext<TermStateModel>, { payload }: UpdateMedicalRecord) {
        return combineLatest([this.store.selectOnce(BaseState.activeContractorId), this.store.selectOnce(TermState.termId)]).pipe(
            switchMap(([contractorId, id]) => this.termsService.updateMedicalRecord(id, contractorId, payload)),
            catchError(err => {
                return throwError(() => err);
            }),
            tap(() => {
                patchState({
                    medicalRecord: {
                        state: ObjectState.VALID,
                        value: payload,
                    },
                });
            }),
        );
    }

    @Action(UploadMedicalRecordDocument)
    uploadMedicalRecordDocument(ctx: StateContext<TermStateModel>, { payload }: UploadMedicalRecordDocument) {
        return this.documentsService.uploadFile(payload.file, payload.comment).pipe(
            catchError(err => {
                if (err.status === 413) {
                    this.mfToast.error('Datoteka je prevelika.');
                } else if (this.helper.checkStructError(err, 'VIRUS')) {
                    this.mfToast.error('Datoteka mogoče vsebuje zlonamerno kodo, nalaganje ni mogoče.');
                } else {
                    this.mfToast.error('Težava pri nalaganju datoteke.');
                }
                return throwError(() => err);
            }),
            filter(event => event.type === HttpEventType.Response),
            withLatestFrom(this.store.selectOnce(BaseState.activeContractorId), this.store.selectOnce(TermState.termId)),
            switchMap(([uploadResponse, contractorId, termId]) => {
                return this.termsService.createMedicalRecordDocument(termId, contractorId, uploadResponse.body).pipe(
                    catchError(err => {
                        // If the error is due to the response not beind a JSON, we can ignore it
                        if (err?.error?.error?.message.includes('JSON.parse')) {
                            return of(null);
                        }
                        this.mfToast.error('Napaka pri nalaganju datoteke.');
                    }),
                );
            }),
        );
    }
}
