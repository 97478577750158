import { Injectable } from '@angular/core';
import { ObjectState, StatefullValue } from '@meddev/fe-shared';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';
import { VendorType } from '../../enums/vendor/vendor-type.enum';
import { LabisExaminationRequestDto } from '../../model/laboratory/lab-examination-request-dto';
import { LabDetailsResponseDto } from '../../model/laboratory/lab-observation-response-dto';
import { LabOrderTemplate } from '../../model/laboratory/lab-order-template-dto';
import { ContractorResponseDto } from '../../model/vendor/contractor-response-dto';
import { ActiveModulesService } from '../../services/activeModules.service';
import { VendorClientService } from '../../services/clients/vendor-client.service';
import { BaseState } from '../base/base.state';
import {
    DeleteOrderTemplate,
    LoadContractorLabs,
    LoadLabDetails,
    LoadOrderTemplates,
    SaveOrderTemplate,
    SetSelectedContractor,
    SetSelectedLab,
    SubmitLabOrder,
} from './laboratory-actions';
import { LaboratoryStateModel } from './laboratory-state.model';

const LABORATORY_STATE_TOKEN: StateToken<LaboratoryStateModel> = new StateToken('laboratorystate');

const DEFAULT_STATE: LaboratoryStateModel = {
    selectedContractorId: { state: ObjectState.UNINIT, value: null } as StatefullValue<number>,
    selectedLabId: { state: ObjectState.UNINIT, value: null } as StatefullValue<number>,
    contractorLabs: { state: ObjectState.UNINIT, value: null } as StatefullValue<ContractorResponseDto[]>,
    selectedLabDetails: { state: ObjectState.UNINIT, value: null } as StatefullValue<LabDetailsResponseDto[]>,
    selectedLabTemplates: { state: ObjectState.UNINIT, value: null } as StatefullValue<LabOrderTemplate[]>,
    submit: { state: ObjectState.VALID, value: false } as StatefullValue<boolean>,
};

@State<LaboratoryStateModel>({
    name: LABORATORY_STATE_TOKEN,
    defaults: DEFAULT_STATE,
    children: [],
})
@Injectable()
export class LaboratoryState {
    constructor(
        private store: Store,
        private vendorClientService: VendorClientService,
        private am: ActiveModulesService,
    ) {
        // this.examinationsManager = new ExaminationsManager(this.examinationsClientService, am);
    }

    @Selector([LABORATORY_STATE_TOKEN])
    public static selectedLabId$(state: LaboratoryStateModel): StatefullValue<number> {
        return state.selectedLabId;
    }

    @Selector([LABORATORY_STATE_TOKEN])
    public static contractorLabs$(state: LaboratoryStateModel): StatefullValue<ContractorResponseDto[]> {
        return state.contractorLabs;
    }

    @Selector([LABORATORY_STATE_TOKEN])
    public static selectedLabDetails$(state: LaboratoryStateModel): StatefullValue<LabDetailsResponseDto[]> {
        return state.selectedLabDetails;
    }

    @Selector([LABORATORY_STATE_TOKEN])
    public static selectedLabTemplates$(state: LaboratoryStateModel): StatefullValue<LabOrderTemplate[]> {
        return state.selectedLabTemplates;
    }

    @Selector([LABORATORY_STATE_TOKEN])
    public static submitOrder$(state: LaboratoryStateModel): StatefullValue<boolean> {
        return state.submit;
    }

    @Action(SetSelectedContractor)
    public setSelectedContractor$(ctx: StateContext<LaboratoryStateModel>, action: SetSelectedContractor): Observable<StatefullValue<number>> {
        return of(action).pipe(
            map(() => ctx.patchState({ selectedContractorId: { state: ObjectState.VALID, value: action.selectedContractorId } }).selectedLabId),
        );
    }

    @Action(SetSelectedLab)
    public setSelectedLab$(ctx: StateContext<LaboratoryStateModel>, action: SetSelectedLab): Observable<unknown> {
        return of(action).pipe(
            tap(() =>
                ctx.patchState({
                    selectedLabDetails: { state: ObjectState.UNINIT, value: null },
                    selectedLabTemplates: { state: ObjectState.UNINIT, value: null },
                }),
            ),
            map(
                () =>
                    ctx.patchState({
                        selectedLabId: { state: action.selectedLabId ? ObjectState.VALID : ObjectState.UNINIT, value: action.selectedLabId },
                    }).selectedLabId,
            ),
            tap(() => {
                ctx.dispatch(new LoadLabDetails(action.selectedLabId));
                ctx.dispatch(new LoadOrderTemplates());
            }),
        );
    }

    @Action(LoadContractorLabs)
    public loadLabs$(ctx: StateContext<LaboratoryStateModel>): Observable<StatefullValue<ContractorResponseDto[]>> {
        return this.store.select(BaseState.activeContractorId).pipe(
            tap(() => ctx.patchState({ contractorLabs: { state: ObjectState.LOADING, value: null } })),
            switchMap(contractorId => {
                if (contractorId) {
                    return this.vendorClientService.getContractor(contractorId, VendorType.LABIS);
                }
                throw new Error();
            }),
            map(response => ctx.patchState({ contractorLabs: { state: ObjectState.VALID, value: response } }).contractorLabs),
            catchError(() => of(ctx.patchState({ contractorLabs: { state: ObjectState.ERROR, value: null } }).contractorLabs)),
        );
    }

    @Action(LoadLabDetails)
    public loadLabDetails$(ctx: StateContext<LaboratoryStateModel>, action: LoadLabDetails): Observable<StatefullValue<LabDetailsResponseDto[]>> {
        return this.store.select(BaseState.activeContractorId).pipe(
            tap(() => ctx.patchState({ selectedLabDetails: { state: ObjectState.LOADING, value: null } })),
            switchMap(contractorId => {
                if (contractorId && action.labId) {
                    return this.vendorClientService.getLaboratoryDetails(contractorId, action.labId);
                }
                ctx.patchState({ selectedLabDetails: { state: ObjectState.UNINIT, value: null } });
                return EMPTY;
            }),
            map(response => ctx.patchState({ selectedLabDetails: { state: ObjectState.VALID, value: response } }).selectedLabDetails),
            catchError(() => of(ctx.patchState({ selectedLabDetails: { state: ObjectState.ERROR, value: null } }).selectedLabDetails)),
        );
    }

    @Action(SubmitLabOrder)
    public submitLabOrder$(ctx: StateContext<LaboratoryStateModel>, action: SubmitLabOrder): Observable<StatefullValue<boolean>> {
        return this.store.select(BaseState.activeContractorId).pipe(
            first(),
            tap(() => ctx.patchState({ submit: { state: ObjectState.LOADING, value: false } })),
            switchMap(contractorId =>
                this.vendorClientService.createLabisReservationExamination(contractorId, action.preReservationId, {
                    labisContractorId: action.labId,
                    observationIds: action.observationIds,
                } as LabisExaminationRequestDto),
            ),
            map(() => {
                ctx.patchState({ submit: { state: ObjectState.VALID, value: false } });
                return ctx.getState().submit;
            }),
            catchError(err => {
                console.error(err);
                ctx.patchState({ submit: { state: ObjectState.ERROR, value: false } });
                return of(ctx.getState().submit);
            }),
        );
    }

    @Action(LoadOrderTemplates)
    public loadOrderTemplates$(ctx: StateContext<LaboratoryStateModel>, action: LoadOrderTemplates): Observable<StatefullValue<LabOrderTemplate[]>> {
        return this.store.select(BaseState.activeContractorId).pipe(
            tap(() => (!action.silent ? ctx.patchState({ selectedLabTemplates: { state: ObjectState.LOADING, value: null } }) : '')),
            switchMap(contractorId => {
                if (contractorId && ctx.getState().selectedLabId?.value) {
                    return this.vendorClientService.getLabOrderTemplates(contractorId, ctx.getState().selectedLabId?.value);
                }
                ctx.patchState({ selectedLabTemplates: { state: ObjectState.UNINIT, value: null } });
                return EMPTY;
            }),
            map(response => ctx.patchState({ selectedLabTemplates: { state: ObjectState.VALID, value: response } }).selectedLabTemplates),
            catchError(() => of(ctx.patchState({ selectedLabTemplates: { state: ObjectState.ERROR, value: null } }).selectedLabTemplates)),
        );
    }

    @Action(SaveOrderTemplate)
    public saveOrderTemplate$(ctx: StateContext<LaboratoryStateModel>, action: SaveOrderTemplate): Observable<unknown> {
        return this.store.select(BaseState.activeContractorId).pipe(
            switchMap(contractorId => {
                if (contractorId && ctx.getState().selectedLabId?.value) {
                    return this.vendorClientService.saveLabOrderTemplate(
                        contractorId,
                        ctx.getState().selectedLabId?.value,
                        action.templateId,
                        action.templateData,
                    );
                }
                ctx.patchState({ selectedLabTemplates: { state: ObjectState.UNINIT, value: null } });
                return EMPTY;
            }),
            tap(() => ctx.dispatch(new LoadOrderTemplates(true))),
        );
    }

    @Action(DeleteOrderTemplate)
    public deleteOrderTemplate$(ctx: StateContext<LaboratoryStateModel>, action: DeleteOrderTemplate): Observable<unknown> {
        return this.store.select(BaseState.activeContractorId).pipe(
            switchMap(contractorId => {
                if (contractorId && ctx.getState().selectedLabId?.value) {
                    return this.vendorClientService.deleteLabOrderTemplate(contractorId, ctx.getState().selectedLabId?.value, action.templateId);
                }
                ctx.patchState({ selectedLabTemplates: { state: ObjectState.UNINIT, value: null } });
                return EMPTY;
            }),
            tap(() => ctx.dispatch(new LoadOrderTemplates(true))),
        );
    }
}
