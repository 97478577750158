import { Injectable } from '@angular/core';
import { ObjectState } from '@meddev/fe-shared';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CustomerService } from '../../../theme/pages/home/_services';
import { DeleteCustomer, LoadCustomers, SetCustomers } from './customer.actions';
import { CustomerStateModel } from './customer.model';

@State<CustomerStateModel>({
    name: 'customer',
    defaults: {
        customers: { state: ObjectState.UNINIT, value: null },
    },
})
@Injectable()
export class CustomerState {
    constructor(private customerService: CustomerService) {}

    @Selector()
    static getCustomers(state: CustomerStateModel) {
        return state.customers;
    }

    @Action(LoadCustomers)
    loadCustomers(ctx: StateContext<CustomerStateModel>, action: LoadCustomers) {
        ctx.setState({ customers: { state: ObjectState.LOADING, value: ctx.getState().customers.value } });
        return this.customerService.getCustomers(action.contractorId, action.searchQuery, action.page, action.limit, action.sorts).pipe(
            tap(customersPage => {
                ctx.setState({ customers: { state: ObjectState.VALID, value: customersPage } });
            }),
            catchError(error => {
                console.error('Error loading customers:', error);
                ctx.setState({ customers: { state: ObjectState.ERROR, value: null } });
                return throwError(() => error);
            }),
        );
    }

    @Action(SetCustomers)
    setCustomers(ctx: StateContext<CustomerStateModel>, action: SetCustomers) {
        ctx.patchState({
            customers: { state: ObjectState.VALID, value: action.customers },
        });
    }

    @Action(DeleteCustomer)
    deleteCustomer(ctx: StateContext<CustomerStateModel>, action: DeleteCustomer) {
        return this.customerService.deactivateCustomer(action.contractorId, action.customerId).pipe(
            tap(() => {
                const customers = ctx.getState().customers.value.result.filter(c => c.id !== action.customerId);

                ctx.patchState({
                    customers: {
                        state: ObjectState.VALID,
                        value: { ...ctx.getState().customers.value, count: ctx.getState().customers.value.count - 1, result: customers },
                    },
                });
            }),
        );
    }
}
